import { Alert, Button, Stack } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import { LoadingTile } from "components/common";
import WithElements from "components/hoc/WithElements";
import Screen from "components/layouts/Screen";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParam } from "react-use";

function PaymentCallback() {
  const [status, setStatus] = useState<
    "loading" | "info" | "success" | "error"
  >("loading");
  const [paymentMessage, setPaymentMessage] = useState<string>(
    "Processing payment..."
  );

  const navigate = useNavigate();
  const stripe = useStripe();
  const payment_intent = useSearchParam("payment_intent");
  const payment_intent_client_secret = useSearchParam(
    "payment_intent_client_secret"
  );
  // const redirect_status = useSearchParam("redirect_status");

  // Retrieve the payment intent
  useEffect(() => {
    if (payment_intent && stripe && payment_intent_client_secret) {
      (async () => {
        try {
          const paymentIntentLookup = await stripe.retrievePaymentIntent(
            payment_intent_client_secret
          );
          const { paymentIntent } = paymentIntentLookup;
          if (!paymentIntent) {
            throw new Error("No payment intent found");
          }

          switch (paymentIntent.status) {
            case "succeeded":
              setStatus("success");
              setPaymentMessage("Payment successful");
              break;
            case "processing":
              setStatus("info");
              setPaymentMessage(
                "Payment processing. We'll update you when payment is received."
              );
              break;

            case "requires_payment_method":
              setStatus("error");
              setPaymentMessage(
                "Payment failed. Please try another payment method."
              );
              break;
            default:
              setStatus("error");
              setPaymentMessage(
                "Something went wrong with payment, please try again."
              );
              break;
          }
        } catch (error) {
          setStatus("error");
          setPaymentMessage("Payment error, please try again");
        }
      })();
    }
  }, [payment_intent, stripe, payment_intent_client_secret]);

  return (
    <Screen title={`Payment ${status}`} backButton={false}>
      <Stack spacing={2}>
        {status === "loading" ? (
          <LoadingTile align="center" sx={{ my: 2 }} />
        ) : (
          <Alert severity={status}>{paymentMessage}</Alert>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
        >
          Home
        </Button>
      </Stack>
    </Screen>
  );
}

export default WithElements(PaymentCallback);
