import { LinearProgress, Paper, Typography, Box } from "@mui/material";
import loadingAnimation from "assets/animations/basketball-spinner.json";
import { useLottie } from "lottie-react";
import { useIsMobile } from "hooks/utils/useIsMobile";

export function LoadingBus() {
  const { View } = useLottie({
    animationData: loadingAnimation,
    loop: true,
    autoplay: true,
  });

  const isMobile = useIsMobile();

  return (
    <Box component={Paper} sx={{ height: "100vh" }}>
      <LinearProgress color="inherit" />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="overline" mt={4}>
          Loading profile...
        </Typography>
        <div style={{ maxWidth: isMobile ? "50%" : "15%" }}>
          <>{View}</>
        </div>
      </Box>
    </Box>
  );
}
