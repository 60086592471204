import { Box, Paper } from "@mui/material";

function Footer() {
  return (
    <footer>
      <Box borderRadius="0" component={Paper} height="60px" />
    </footer>
  );
}

export default Footer;
