import {
  AdminPanelSettings as AdminIcon,
  ArrowRightOutlined as OpenMoreIcon,
  ChangeCircle,
  Gavel as RulesIcon,
  Home as HomeIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  PeopleOutline as TeamsIcon,
  SettingsOutlined as SettingsIcon,
  SportsSoccer as GamesIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Backdrop,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { apolloClient } from "App";
import { LoadingTile } from "components/common";
import { useLogoutMutation } from "graphql/generated";
import { AuthProviders } from "graphql/generated";
import { useActiveTeamProfile, usePlayerProfiles, useProfile } from "hooks";
import { useState } from "react";
// import { useGoogleLogout } from "react-google-login";
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

export default function AccountMenu() {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [rootAnchorEl, setRootAnchorEl] = useState<null | HTMLElement>(null);
  const [teamsAnchorEl, setTeamsAnchorEl] = useState<null | HTMLElement>(null);

  const { profile } = useProfile();
  const navigate = useNavigate();
  const [emailLogout] = useLogoutMutation();
  const { activeTeam: activeTeamLookup } = useActiveTeamProfile();
  const {
    playerProfiles,
    handlers: { toggleActiveTeam },
  } = usePlayerProfiles();

  const activeTeam = playerProfiles.find(
    (playerProfile) => playerProfile.team.id === activeTeamLookup?.team?.id
  );

  const isRootMenuOpen = Boolean(rootAnchorEl);
  const isTeamMenuOpen = Boolean(teamsAnchorEl);

  const handleRootClick = (event: React.MouseEvent<HTMLElement>) => {
    setRootAnchorEl(event.currentTarget);
  };

  const handleTeamMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setTeamsAnchorEl(event.currentTarget);
  };

  const handleRootClose = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const clickedElementId = (event.target as any)?.id;
    clickedElementId !== "team-menu" && setRootAnchorEl(null);
  };

  const handleTeamMenuClose = async (event: any) => {
    // Lookup the team that was clicked
    const clickedTeamID = (event.target as any)?.id;

    if (clickedTeamID === "create-team") {
      navigate("/teams/create");
      return;
    } else if (clickedTeamID === "join-team") {
      navigate("/teams/join");
      return;
    }

    setTeamsAnchorEl(null);
    setRootAnchorEl(null);
    if (!clickedTeamID) {
      return;
    }

    // If user clicked on a team, toggle it as active team
    setIsLoading(true);
    setLoadingMessage("Loading team...");
    await toggleActiveTeam(clickedTeamID);
    setIsLoading(false);
  };

  async function handleLogoutPressed() {
    setIsLoading(true);
    setLoadingMessage("Logging out...");
    emailLogout();
    await apolloClient.clearStore();
    if (profile?.authProvider === AuthProviders.GOOGLE) {
      googleLogout();
    }
    setIsLoading(false);
    navigate("/login");
  }

  return (
    <>
      <Backdrop open={isLoading}>
        <Box
          sx={{
            p: 8,
            bgcolor: "black",
          }}
        >
          <LoadingTile align="center" message={loadingMessage} />
        </Box>
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          ml: 1,
        }}
      >
        <Tooltip title="Account settings">
          <IconButton onClick={handleRootClick} size="small">
            <MenuIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={rootAnchorEl}
        open={isRootMenuOpen}
        onClose={handleRootClose}
        onClick={handleRootClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => navigate(`/profile/${profile?.player.id}`)}>
          <Avatar
            alt={profile?.player.name ?? ""}
            src={activeTeam?.picture ?? ""}
          />{" "}
          Profile
        </MenuItem>
        <MenuItem onClick={() => navigate("/")}>
          <ListItemIcon>
            <HomeIcon fontSize="small" />
          </ListItemIcon>
          Home
        </MenuItem>
        <Divider />
        <MenuItem dense id="team-menu" onClick={handleTeamMenuClick}>
          <ListItemIcon>
            <TeamsIcon fontSize="small" />
          </ListItemIcon>
          Teams
          <ListItemIcon>
            <OpenMoreIcon fontSize="small" />
          </ListItemIcon>
        </MenuItem>

        <MenuItem dense onClick={() => navigate("/games")}>
          <ListItemIcon>
            <GamesIcon fontSize="small" />
          </ListItemIcon>
          Games
        </MenuItem>

        <MenuItem dense onClick={() => navigate("/rules")}>
          <ListItemIcon>
            <RulesIcon fontSize="small" />
          </ListItemIcon>
          Rules
        </MenuItem>

        <MenuItem dense onClick={() => navigate("/admin")}>
          <ListItemIcon>
            <AdminIcon fontSize="small" />
          </ListItemIcon>
          Team Admin
        </MenuItem>
        <MenuItem dense onClick={() => navigate("/settings")}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          Settings & Payment
        </MenuItem>
        <MenuItem dense onClick={() => navigate("/changelog")}>
          <ListItemIcon>
            <ChangeCircle fontSize="small" />
          </ListItemIcon>
          Change Log
        </MenuItem>
        <MenuItem dense onClick={handleLogoutPressed}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={teamsAnchorEl}
        open={isTeamMenuOpen}
        onClose={handleTeamMenuClose}
        onClick={handleTeamMenuClose}
      >
        {playerProfiles.map((profile) => (
          <MenuItem
            dense
            key={profile.id}
            id={profile.team.id}
            selected={profile.team.id === activeTeamLookup?.team?.id}
          >
            {profile.team.name}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem dense id="create-team">
          Create New Team
        </MenuItem>
        <MenuItem dense id="join-team">
          Join Team
        </MenuItem>
      </Menu>
    </>
  );
}
