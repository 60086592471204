import { ButtonBase, Typography } from "@mui/material";
import { ReactComponent as GoogleIcon } from "assets/icons/google.svg";

interface SocialButtonProps {
  type: "google";
  onClick: () => void;
  children: string;
}

const svgStyles = { marginRight: "12px", height: "24px", width: "24px" };
const loginButtonStyles = {
  width: "100%",
  backgroundColor: "background.default",
  height: "42px",
  border: "1px solid rgba(201, 201, 201, 0.29)",
};

export function SocialButton(props: SocialButtonProps) {
  const { type, onClick, children } = props;

  let renderIcon = null;
  switch (type) {
    case "google":
      renderIcon = <GoogleIcon style={svgStyles} />;
      break;
  }

  return (
    <ButtonBase onClick={onClick} sx={loginButtonStyles}>
      {renderIcon}
      <Typography variant="button">{children}</Typography>
    </ButtonBase>
  );
}
