import { useGetMyBalanceQuery } from "graphql/generated";

export function useBalance() {
  const {
    data,
    loading: balanceLoading,
    error: balanceError,
  } = useGetMyBalanceQuery();

  const balance = data?.getPlayerBalance ?? 0;
  return { balance, balanceLoading, balanceError };
}
