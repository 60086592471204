import { Link as MuiLink, SxProps, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { useNavigate } from "react-router-dom";

interface LinkProps {
  children: string;
  to: string;
  variant?: Variant;
  sx?: SxProps;
}

export function Link(props: LinkProps) {
  const { to, children, variant = "body2" } = props;
  const navigate = useNavigate();

  function handleClick() {
    navigate(to);
  }

  return (
    <MuiLink
      onClick={handleClick}
      sx={{
        textDecoration: "none",
        cursor: "pointer",
        fontSize: "1.25em",
      }}
    >
      <Typography variant={variant} sx={{ ...props.sx }}>
        {children}
      </Typography>
    </MuiLink>
  );
}
