import { format, formatDistance, isPast } from "date-fns";
import {
  DayOfWeek,
  GamesStatus,
  PlayerStatus,
  PlayerTeamsStatus,
  ReoccuringGameFrequency,
} from "graphql/generated";

export function formatDateTimeDistance(
  dateStringISO: string | undefined | null,
  formatString: string
) {
  try {
    if (!dateStringISO) {
      throw new Error("Invalid date");
    }
    const dateObject = new Date(dateStringISO);
    const formattedDate = format(dateObject, formatString);
    const distance = formatDistance(dateObject, new Date(), {
      addSuffix: true,
    });
    return [formattedDate, distance];
  } catch (error) {
    return ["", ""];
  }
}

export function formatGameDay(day: string) {
  switch (day) {
    case DayOfWeek.MONDAY:
      return "Monday";
    case DayOfWeek.TUESDAY:
      return "Tuesday";
    case DayOfWeek.WEDNESDAY:
      return "Wednesday";
    case DayOfWeek.THURSDAY:
      return "Thursday";
    case DayOfWeek.FRIDAY:
      return "Friday";
    case DayOfWeek.SATURDAY:
      return "Saturday";
    case DayOfWeek.SUNDAY:
      return "Sunday";
  }
}

export function formatGameFrequency(frequency: ReoccuringGameFrequency) {
  switch (frequency) {
    case ReoccuringGameFrequency.WEEKLY:
      return "Weekly";
    case ReoccuringGameFrequency.FORTNIGHTLY:
      return "Fortnightly";
    case ReoccuringGameFrequency.MONTHLY:
      return "Monthly";
  }
}

export function formatPlayerStatus(status: PlayerStatus) {
  switch (status) {
    case PlayerStatus.ACTIVE:
      return "Active";
    case PlayerStatus.INACTIVE:
      return "Inactive";
  }
}

export function formatPlayerTeamStatus(status?: PlayerTeamsStatus) {
  switch (status) {
    case PlayerTeamsStatus.ACTIVE:
      return "Active";
    case PlayerTeamsStatus.INACTIVE:
      return "Inactive";
  }
}

export function formatGameStatus(status: GamesStatus, gameDate: Date) {
  switch (status) {
    case GamesStatus.OPEN:
      return isPast(gameDate) ? "Awaiting Finalisation" : "Open";
    case GamesStatus.FINALISED:
      return "Complete";
  }
}
