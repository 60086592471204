import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type Attendance = {
  __typename?: 'Attendance';
  createdAt: Scalars['DateTime'];
  createdByPlayer: Player;
  game: Game;
  id: Scalars['String'];
  player: Player;
  team: Team;
};

/** How the player has signed up */
export enum AuthProviders {
  EMAIL = 'EMAIL',
  GOOGLE = 'GOOGLE'
}

export type CashOutRequestInput = {
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  amount: Scalars['Float'];
  bsb: Scalars['String'];
  note: Scalars['String'];
  type: CashOutType;
};

/** full or partial */
export enum CashOutType {
  full = 'full',
  partial = 'partial'
}

export type CreateGameInput = {
  date: Scalars['DateTime'];
  maxPlayers: Scalars['Int'];
  minPlayers: Scalars['Int'];
  name: Scalars['String'];
  venueID: Scalars['String'];
};

export type CreatePostGameReviewInput = {
  gameID: Scalars['String'];
  goals: Scalars['Float'];
  highlight?: InputMaybe<Scalars['String']>;
  mvpVotePlayerID: Scalars['String'];
  teamRating: Scalars['Float'];
  win: Scalars['Boolean'];
};

export type CreateReoccuringGameInput = {
  frequency: ReoccuringGameFrequency;
  gameDay: DayOfWeek;
  gameTime: Scalars['DateTime'];
  maxPlayers: Scalars['Int'];
  minPlayers: Scalars['Int'];
  name: Scalars['String'];
  venueID: Scalars['String'];
};

export type CreateSubscriptionResponse = {
  __typename?: 'CreateSubscriptionResponse';
  clientSecret: Scalars['String'];
  subscriptionID: Scalars['String'];
};

export type CreateTeamInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  whatsappJoinLink?: InputMaybe<Scalars['String']>;
};

export type CreateTeamRuleInput = {
  ruleDetails: Scalars['String'];
  ruleTitle: Scalars['String'];
};

export type CreateVenueArgs = {
  address?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['Upload']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['Int']>;
  website?: InputMaybe<Scalars['String']>;
};

/** Day of the week */
export enum DayOfWeek {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY'
}

export type EditTeamRuleInput = {
  ruleDetails: Scalars['String'];
  ruleID: Scalars['String'];
  ruleTitle: Scalars['String'];
};

export type EmailRegisterInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};

export type FinaliseGameInputs = {
  fee: Scalars['Float'];
  gameID: Scalars['String'];
  paidByPlayerID: Scalars['String'];
  playerIDs: Array<Scalars['String']>;
  transactionName: Scalars['String'];
};

export type Game = {
  __typename?: 'Game';
  attendance: Array<Attendance>;
  createdByPlayer?: Maybe<Player>;
  /** Date the game will is played */
  date: Scalars['DateTime'];
  deletedByPlayer?: Maybe<Player>;
  /** Date the game was finalised and charged */
  finalisedDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  inVoting: Scalars['Boolean'];
  /** Max number of players the game can play */
  maxPlayers: Scalars['Int'];
  /** Minimum number of players to have a game */
  minPlayers: Scalars['Int'];
  /** Player that was voted the MVP of the game */
  mvpPlayer?: Maybe<Player>;
  /** Name or title of the game */
  name: Scalars['String'];
  reviews: Array<PostGameReview>;
  /** Open or Finalised */
  status: GamesStatus;
  /** Team that is playing the game */
  team: Team;
  transactions: Array<Transaction>;
  updatedByPlayer?: Maybe<Player>;
  /** Venue where the game is played */
  venue: Venue;
  weather: WeatherReport;
};

/** Filter game list by status */
export enum GameListStatusFilter {
  All = 'All',
  Complete = 'Complete',
  Finalised = 'Finalised',
  ReadyToFinalise = 'ReadyToFinalise',
  Upcoming = 'Upcoming'
}

/** Open or Finalised */
export enum GamesStatus {
  FINALISED = 'FINALISED',
  OPEN = 'OPEN'
}

export type GetHistoricalGameInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  orderBy: Scalars['String'];
  orderDirection: Scalars['String'];
  page: Scalars['Int'];
  rowsPerPage: Scalars['Float'];
  searchText: Scalars['String'];
  status: GameListStatusFilter;
};

export type GetHistoricalGamesResponse = {
  __typename?: 'GetHistoricalGamesResponse';
  games: Array<Game>;
  totalGames: Scalars['Int'];
};

export type GetNotificationInputs = {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

export type InvitePlayerInputs = {
  email: Scalars['String'];
};

export type JoinRequest = {
  __typename?: 'JoinRequest';
  createdAt: Scalars['DateTime'];
  createdByPlayer?: Maybe<Player>;
  id: Scalars['String'];
  player?: Maybe<Player>;
  status: TeamJoinRequestStatus;
  team?: Maybe<Team>;
  updatedAt: Scalars['DateTime'];
  updatedByPlayer?: Maybe<Player>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptJoinRequest: Scalars['Boolean'];
  addPlayerToGame: Scalars['Boolean'];
  cancelSubscription: Scalars['Boolean'];
  cashOutRequest: Scalars['Boolean'];
  confirmEmail: Scalars['Boolean'];
  createGame: Game;
  createPostGameReview: Scalars['Boolean'];
  createReoccuringGame: ReoccuringGame;
  createTeam: Team;
  createTeamRule: Rule;
  createVenue: Venue;
  deleteGame: Game;
  deleteReoccuringGame: Scalars['Boolean'];
  deleteTeamRule: Scalars['Boolean'];
  deleteVenue: Scalars['Boolean'];
  emailLogin: LoginResponse;
  emailRegister: LoginResponse;
  finaliseGame: Scalars['Boolean'];
  googleLogin: LoginResponse;
  googleRegister: LoginResponse;
  initialiseSubscription: CreateSubscriptionResponse;
  initialiseTopupPayment: initialiseTopupPaymentResponse;
  invitePlayerToJoinTeam: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  markAllRead: Scalars['Boolean'];
  rejectJoinRequest: Scalars['Boolean'];
  removePlayerFromGame: Scalars['Boolean'];
  /** Remove a player from the team */
  removePlayerFromTeam: Scalars['Boolean'];
  requestToJoinTeam: Scalars['Boolean'];
  resendConfirmationEmail: LoginResponse;
  revokeRefreshTokensForUser: Scalars['Boolean'];
  spendMoney: Array<Transaction>;
  /** @deprecated Taking cash can be abused with the cash out functionality */
  takeMoney: Transaction;
  toggleActiveTeam: Scalars['Boolean'];
  updateGame: Game;
  updatePlayer: Player;
  updatePlayersTeamProfile: Scalars['Boolean'];
  updatePostGameReview: Scalars['Boolean'];
  updateReoccuringGame: ReoccuringGame;
  updateTeam: Team;
  updateTeamRule: Rule;
  updateVenue: Venue;
};


export type MutationacceptJoinRequestArgs = {
  joinRequestID: Scalars['String'];
};


export type MutationaddPlayerToGameArgs = {
  gameID: Scalars['String'];
  playerID: Scalars['String'];
};


export type MutationcashOutRequestArgs = {
  input: CashOutRequestInput;
};


export type MutationconfirmEmailArgs = {
  token: Scalars['String'];
};


export type MutationcreateGameArgs = {
  data: CreateGameInput;
};


export type MutationcreatePostGameReviewArgs = {
  data: CreatePostGameReviewInput;
};


export type MutationcreateReoccuringGameArgs = {
  input: CreateReoccuringGameInput;
};


export type MutationcreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationcreateTeamRuleArgs = {
  input: CreateTeamRuleInput;
};


export type MutationcreateVenueArgs = {
  data: CreateVenueArgs;
};


export type MutationdeleteGameArgs = {
  gameID: Scalars['String'];
};


export type MutationdeleteReoccuringGameArgs = {
  id: Scalars['String'];
};


export type MutationdeleteTeamRuleArgs = {
  ruleID: Scalars['String'];
};


export type MutationdeleteVenueArgs = {
  venueid: Scalars['String'];
};


export type MutationemailLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationemailRegisterArgs = {
  input: EmailRegisterInput;
};


export type MutationfinaliseGameArgs = {
  input: FinaliseGameInputs;
};


export type MutationgoogleLoginArgs = {
  token: Scalars['String'];
};


export type MutationgoogleRegisterArgs = {
  code: Scalars['String'];
};


export type MutationinitialiseSubscriptionArgs = {
  pricingID: Scalars['String'];
};


export type MutationinitialiseTopupPaymentArgs = {
  pricingID: Scalars['String'];
};


export type MutationinvitePlayerToJoinTeamArgs = {
  input: InvitePlayerInputs;
};


export type MutationrejectJoinRequestArgs = {
  joinRequestID: Scalars['String'];
};


export type MutationremovePlayerFromGameArgs = {
  gameID: Scalars['String'];
  playerID: Scalars['String'];
};


export type MutationremovePlayerFromTeamArgs = {
  teamProfileID: Scalars['String'];
};


export type MutationrequestToJoinTeamArgs = {
  teamCode: Scalars['String'];
};


export type MutationrevokeRefreshTokensForUserArgs = {
  playerID: Scalars['String'];
};


export type MutationspendMoneyArgs = {
  input: SpendMoneyInput;
};


export type MutationtakeMoneyArgs = {
  input: TakeMoneyInput;
};


export type MutationtoggleActiveTeamArgs = {
  newTeamID: Scalars['String'];
};


export type MutationupdateGameArgs = {
  data: UpdateGameInput;
};


export type MutationupdatePlayerArgs = {
  inputs: UpdatePlayerInputs;
};


export type MutationupdatePlayersTeamProfileArgs = {
  data: UpdatePlayerTeamInput;
};


export type MutationupdatePostGameReviewArgs = {
  data: UpdatePostGameReviewInput;
};


export type MutationupdateReoccuringGameArgs = {
  input: UpdateReoccuringGameInput;
};


export type MutationupdateTeamArgs = {
  input: UpdateTeamInput;
};


export type MutationupdateTeamRuleArgs = {
  input: EditTeamRuleInput;
};


export type MutationupdateVenueArgs = {
  data: UpdateVenueArgs;
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isRead: Scalars['Boolean'];
  message: Scalars['String'];
  player?: Maybe<Player>;
  priority: NotificationPriority;
  redirect?: Maybe<Scalars['String']>;
};

/** low medium or high */
export enum NotificationPriority {
  high = 'high',
  low = 'low',
  medium = 'medium'
}

export type PaymentCard = {
  __typename?: 'PaymentCard';
  brand: Scalars['String'];
  last4: Scalars['String'];
};

export type PlaceDetails = {
  __typename?: 'PlaceDetails';
  formatted_address?: Maybe<Scalars['String']>;
  formatted_phone_number?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  types?: Maybe<Array<Scalars['String']>>;
  website?: Maybe<Scalars['String']>;
};

export type PlacePredictionResult = {
  __typename?: 'PlacePredictionResult';
  description: Scalars['String'];
  distance_meters?: Maybe<Scalars['Float']>;
  matched_substrings: Array<PredictionSubstring>;
  place_id: Scalars['String'];
  structured_formatting: StructuredFormatting;
  terms: Array<PredictionTerm>;
  types: Array<Scalars['String']>;
};

export type Player = {
  __typename?: 'Player';
  activeTeam?: Maybe<Team>;
  attendance: Array<Attendance>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedByPlayer?: Maybe<Player>;
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  ownedTeams: Array<Team>;
  status: PlayerStatus;
  teamProfile?: Maybe<TeamProfile>;
  updatedAt: Scalars['DateTime'];
  updatedByPlayer?: Maybe<Player>;
};

/** Active, Inactive, or Deleted */
export enum PlayerStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE'
}

/** Active or Inactive */
export enum PlayerTeamsStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export type PostGameReview = {
  __typename?: 'PostGameReview';
  createdAt: Scalars['DateTime'];
  createdByPlayer: Player;
  game: Game;
  goals: Scalars['Int'];
  highlight?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mvpVotePlayer: Player;
  player: Player;
  team: Team;
  teamRating: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  updatedByPlayer: Player;
  win: Scalars['Boolean'];
};

export type PredictionSubstring = {
  __typename?: 'PredictionSubstring';
  length: Scalars['Float'];
  offset: Scalars['Float'];
};

export type PredictionTerm = {
  __typename?: 'PredictionTerm';
  offset: Scalars['String'];
  value: Scalars['String'];
};

export type Pricing = {
  __typename?: 'Pricing';
  amountCents: Scalars['Int'];
  currency: Scalars['String'];
  id: Scalars['String'];
  interval?: Maybe<SubscriptionInterval>;
};

export type Product = {
  __typename?: 'Product';
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  pricing: Array<Pricing>;
  status: ProductStatus;
};

/** active, inactive or comingsoon */
export enum ProductStatus {
  Active = 'Active',
  ComingSoon = 'ComingSoon',
  Inactive = 'Inactive'
}

export type Profile = {
  __typename?: 'Profile';
  authProvider: AuthProviders;
  /** Average goals scored per game attended */
  averageGoalsPerMatch: Scalars['Float'];
  /** Has the user confirmed their email? */
  emailConfirmed: Scalars['Boolean'];
  player: Player;
  playersOnTeam: Array<Player>;
  /** Get the players most recent post game reviews */
  recentReviews: Array<PostGameReview>;
  /** Get the players most recent transactions */
  recentTransactions: Array<Transaction>;
  /** Get the players active team profile */
  teamProfile?: Maybe<TeamProfile>;
  /** Get the players teams */
  teams: Array<Team>;
  /** Total games attended that have been finalised */
  totalGames: Scalars['Float'];
  /** Get the players total games won */
  totalGamesWon: Scalars['Float'];
  /** Total number of goals the player has scored */
  totalGoals: Scalars['Float'];
  /** Total times player has been voted the MVP of the game */
  totalMvpVotes: Scalars['Float'];
  /** Total times player has won the MVP of the game */
  totalMvpWins: Scalars['Float'];
};


export type ProfilerecentTransactionsArgs = {
  count?: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  getBalanceTopupPlans: Array<Product>;
  /** Get details of a game */
  getGame?: Maybe<Game>;
  getHistoricalGames: GetHistoricalGamesResponse;
  getMyPendingJoinRequests: Array<JoinRequest>;
  getMyProfile: Profile;
  getNotifications: Array<Notification>;
  getPlayerBalance: Scalars['Float'];
  getPlayersActiveTeamProfile?: Maybe<TeamProfile>;
  /** Get the players on the players active team */
  getPlayersOnTeam: Array<Player>;
  getPlayersProfile: Profile;
  /** Get all the players team profiles */
  getPlayersTeams: Array<TeamProfile>;
  getPostGameReview?: Maybe<PostGameReview>;
  getRecentlyFinalisedGames: Array<RecentlyFinalisedGame>;
  getReoccuringGames: Array<ReoccuringGame>;
  getTeam?: Maybe<Team>;
  getTeamConfig: Array<TeamConfig>;
  getTeamJoinRequests: Array<JoinRequest>;
  getTeamPaymentPlans: Array<Product>;
  getTeamRules: Array<Rule>;
  /** Get the subscription status of the players active team */
  getTeamSubscription?: Maybe<Subscription>;
  getUpcomingGames: Array<Game>;
  getVenues: Array<Venue>;
  lookupPlace: PlaceDetails;
  lookupPlacePredictions: Array<PlacePredictionResult>;
};


export type QuerygetGameArgs = {
  gameID: Scalars['String'];
};


export type QuerygetHistoricalGamesArgs = {
  input: GetHistoricalGameInput;
};


export type QuerygetNotificationsArgs = {
  inputs: GetNotificationInputs;
};


export type QuerygetPlayersProfileArgs = {
  playerID: Scalars['String'];
};


export type QuerygetPostGameReviewArgs = {
  reviewID: Scalars['String'];
};


export type QuerygetTeamArgs = {
  teamID: Scalars['String'];
};


export type QuerylookupPlaceArgs = {
  placeid: Scalars['String'];
};


export type QuerylookupPlacePredictionsArgs = {
  address: Scalars['String'];
};

export type RecentlyFinalisedGame = {
  __typename?: 'RecentlyFinalisedGame';
  game: Game;
  review?: Maybe<PostGameReview>;
};

export type ReoccuringGame = {
  __typename?: 'ReoccuringGame';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByPlayer?: Maybe<Player>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedByPlayer?: Maybe<Player>;
  frequency: ReoccuringGameFrequency;
  gameDay: DayOfWeek;
  gameTime: Scalars['DateTime'];
  id: Scalars['String'];
  maxPlayers: Scalars['Float'];
  minPlayers: Scalars['Float'];
  name: Scalars['String'];
  status: ReoccuringGameStatus;
  team: Team;
  updatedAt: Scalars['DateTime'];
  updatedByPlayer?: Maybe<Player>;
  venue: Venue;
};

/** Weekly, Fortnightly or Monthly */
export enum ReoccuringGameFrequency {
  FORTNIGHTLY = 'FORTNIGHTLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY'
}

/** Running or Paused */
export enum ReoccuringGameStatus {
  PAUSED = 'PAUSED',
  RUNNING = 'RUNNING'
}

export type Rule = {
  __typename?: 'Rule';
  createdAt: Scalars['DateTime'];
  createdByPlayer?: Maybe<Player>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedByPlayer?: Maybe<Player>;
  id: Scalars['String'];
  ruleDetails: Scalars['String'];
  ruleTitle: Scalars['String'];
  team: Team;
  updatedAt: Scalars['DateTime'];
  updatedByPlayer?: Maybe<Player>;
};

export type SpendMoneyInput = {
  amount: Scalars['Float'];
  description: Scalars['String'];
  playerIDs: Array<Scalars['String']>;
  whoPaid: Scalars['String'];
};

export type StructuredFormatting = {
  __typename?: 'StructuredFormatting';
  main_text: Scalars['String'];
  main_text_matched_substrings: Array<PredictionSubstring>;
  secondary_text: Scalars['String'];
  secondary_text_matched_substrings?: Maybe<Array<PredictionSubstring>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  card?: Maybe<PaymentCard>;
  current_period_end: Scalars['DateTime'];
  id: Scalars['String'];
  product?: Maybe<Product>;
  status: SubscriptionStatus;
};

/** month or year, based on stripe options */
export enum SubscriptionInterval {
  month = 'month',
  year = 'year'
}

/** Same as Stripe: https://stripe.com/docs/billing/subscriptions/overview#subscription-statuses */
export enum SubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  past_due = 'past_due',
  unpaid = 'unpaid'
}

export type TakeMoneyInput = {
  amount: Scalars['Int'];
  description: Scalars['String'];
  playerID: Scalars['String'];
};

export type Team = {
  __typename?: 'Team';
  active: Scalars['Boolean'];
  attendance: Array<Attendance>;
  createdAt: Scalars['DateTime'];
  createdByPlayer?: Maybe<Player>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedByPlayer?: Maybe<Player>;
  description: Scalars['String'];
  games: Array<Game>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  joinCode: Scalars['String'];
  name: Scalars['String'];
  owner: Player;
  players: Array<Player>;
  postGameReviews: Array<PostGameReview>;
  reoccuringGames: Array<ReoccuringGame>;
  teamProfiles: Array<TeamProfile>;
  transactions: Array<Transaction>;
  updatedAt: Scalars['DateTime'];
  updatedByPlayer?: Maybe<Player>;
  venues: Array<Venue>;
  whatsappJoinLink?: Maybe<Scalars['String']>;
};

export type TeamConfig = {
  __typename?: 'TeamConfig';
  configItem: TeamConfigItem;
  configValue: Scalars['String'];
  configValueType: TeamConfigType;
  createdAt: Scalars['DateTime'];
  createdByPlayer?: Maybe<Player>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedByPlayer?: Maybe<Player>;
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedByPlayer?: Maybe<Player>;
};

/** Team config items */
export enum TeamConfigItem {
  RULES_EDITABLE = 'RULES_EDITABLE',
  VOTING_PERIOD_HOURS = 'VOTING_PERIOD_HOURS'
}

/** Team config value data type: string number or bool */
export enum TeamConfigType {
  boolean = 'boolean',
  number = 'number',
  string = 'string'
}

/** Pending, approved or rejected */
export enum TeamJoinRequestStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type TeamProfile = {
  __typename?: 'TeamProfile';
  createdAt: Scalars['DateTime'];
  createdByPlayer?: Maybe<Player>;
  hasJoinedTeamChat: Scalars['Boolean'];
  id: Scalars['String'];
  isTeamAdmin: Scalars['Boolean'];
  picture?: Maybe<Scalars['String']>;
  player: Player;
  position?: Maybe<Scalars['String']>;
  status: PlayerTeamsStatus;
  team: Team;
  updatedAt: Scalars['DateTime'];
  updatedByPlayer?: Maybe<Player>;
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdByPlayer: Player;
  game?: Maybe<Game>;
  id: Scalars['String'];
  name: Scalars['String'];
  player: Player;
  team: Team;
  type: TransactionType;
};

/** Debit or credit */
export enum TransactionType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT'
}

export type UpdateGameInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  gameID: Scalars['String'];
  maxPlayers?: InputMaybe<Scalars['Int']>;
  minPlayers?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  venueID?: InputMaybe<Scalars['String']>;
};

export type UpdatePlayerInputs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdatePlayerTeamInput = {
  id: Scalars['String'];
  isTeamAdmin?: InputMaybe<Scalars['Boolean']>;
  picture?: InputMaybe<Scalars['Upload']>;
  position?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PlayerTeamsStatus>;
};

export type UpdatePostGameReviewInput = {
  goals?: InputMaybe<Scalars['Float']>;
  highlight?: InputMaybe<Scalars['String']>;
  mvpVotePlayerID?: InputMaybe<Scalars['String']>;
  postGameReviewID: Scalars['String'];
  teamRating?: InputMaybe<Scalars['Float']>;
  win?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateReoccuringGameInput = {
  frequency?: InputMaybe<ReoccuringGameFrequency>;
  gameDay?: InputMaybe<DayOfWeek>;
  gameTime?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  maxPlayers?: InputMaybe<Scalars['Int']>;
  minPlayers?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReoccuringGameStatus>;
  venueID?: InputMaybe<Scalars['String']>;
};

export type UpdateTeamInput = {
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  whatsappJoinLink?: InputMaybe<Scalars['String']>;
};

export type UpdateVenueArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  logo?: InputMaybe<Scalars['Upload']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['Int']>;
  website?: InputMaybe<Scalars['String']>;
};

export type Venue = {
  __typename?: 'Venue';
  active: Scalars['Boolean'];
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdByPlayer: Player;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedByPlayer?: Maybe<Player>;
  games: Array<Game>;
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['Int']>;
  team: Team;
  updatedAt: Scalars['DateTime'];
  updatedByPlayer: Player;
  website?: Maybe<Scalars['String']>;
};

export type WeatherReport = {
  __typename?: 'WeatherReport';
  gameTemp: Scalars['Float'];
  max: Scalars['Int'];
  min: Scalars['Int'];
  precis: Scalars['String'];
  precisCode: Scalars['String'];
  rainfallProbability: Scalars['Int'];
};

export type initialiseTopupPaymentResponse = {
  __typename?: 'initialiseTopupPaymentResponse';
  clientSecret: Scalars['String'];
};

export type GetReoccuringGamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReoccuringGamesQuery = { __typename?: 'Query', getReoccuringGames: Array<{ __typename?: 'ReoccuringGame', id: string, name: string, status: ReoccuringGameStatus, frequency: ReoccuringGameFrequency, minPlayers: number, maxPlayers: number, gameDay: DayOfWeek, gameTime: any, venue: { __typename?: 'Venue', id: string, name: string } }> };

export type CreateReoccuringGameMutationVariables = Exact<{
  input: CreateReoccuringGameInput;
}>;


export type CreateReoccuringGameMutation = { __typename?: 'Mutation', createReoccuringGame: { __typename?: 'ReoccuringGame', id: string } };

export type UpdateReoccuringGameMutationVariables = Exact<{
  input: UpdateReoccuringGameInput;
}>;


export type UpdateReoccuringGameMutation = { __typename?: 'Mutation', updateReoccuringGame: { __typename?: 'ReoccuringGame', id: string } };

export type DeleteReoccuringGameMutationVariables = Exact<{
  deleteReoccuringGameId: Scalars['String'];
}>;


export type DeleteReoccuringGameMutation = { __typename?: 'Mutation', deleteReoccuringGame: boolean };

export type GetRecentlyFinalisedGamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRecentlyFinalisedGamesQuery = { __typename?: 'Query', getRecentlyFinalisedGames: Array<{ __typename?: 'RecentlyFinalisedGame', game: { __typename?: 'Game', id: string, date: any, name: string, status: GamesStatus, finalisedDate?: any | null, mvpPlayer?: { __typename?: 'Player', id: string, name: string } | null, venue: { __typename?: 'Venue', id: string, name: string }, attendance: Array<{ __typename?: 'Attendance', player: { __typename?: 'Player', id: string, name: string } }> }, review?: { __typename?: 'PostGameReview', id: string, goals: number, highlight?: string | null, updatedAt: any, mvpVotePlayer: { __typename?: 'Player', id: string, name: string } } | null }> };

export type CreatePostGameReviewMutationVariables = Exact<{
  data: CreatePostGameReviewInput;
}>;


export type CreatePostGameReviewMutation = { __typename?: 'Mutation', createPostGameReview: boolean };

export type UpdatePostGameReviewMutationVariables = Exact<{
  data: UpdatePostGameReviewInput;
}>;


export type UpdatePostGameReviewMutation = { __typename?: 'Mutation', updatePostGameReview: boolean };

export type GetPostGameReviewQueryVariables = Exact<{
  reviewId: Scalars['String'];
}>;


export type GetPostGameReviewQuery = { __typename?: 'Query', getPostGameReview?: { __typename?: 'PostGameReview', id: string, goals: number, win: boolean, highlight?: string | null, teamRating: number, player: { __typename?: 'Player', id: string, name: string }, mvpVotePlayer: { __typename?: 'Player', id: string, name: string }, game: { __typename?: 'Game', id: string, name: string, attendance: Array<{ __typename?: 'Attendance', id: string, player: { __typename?: 'Player', id: string, name: string } }> }, team: { __typename?: 'Team', id: string, name: string } } | null };

export type GetUpcomingGamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUpcomingGamesQuery = { __typename?: 'Query', getUpcomingGames: Array<{ __typename?: 'Game', id: string, date: any, name: string, maxPlayers: number, minPlayers: number, status: GamesStatus, weather: { __typename?: 'WeatherReport', precis: string, precisCode: string, rainfallProbability: number, gameTemp: number, min: number, max: number }, attendance: Array<{ __typename?: 'Attendance', id: string, player: { __typename?: 'Player', id: string, name: string, teamProfile?: { __typename?: 'TeamProfile', position?: string | null, picture?: string | null } | null } }>, venue: { __typename?: 'Venue', id: string, name: string, logo?: string | null, address?: string | null, phone?: string | null, website?: string | null } }> };

export type AddPlayerToGameMutationVariables = Exact<{
  gameId: Scalars['String'];
  playerId: Scalars['String'];
}>;


export type AddPlayerToGameMutation = { __typename?: 'Mutation', addPlayerToGame: boolean };

export type RemovePlayerFromGameMutationVariables = Exact<{
  playerId: Scalars['String'];
  gameId: Scalars['String'];
}>;


export type RemovePlayerFromGameMutation = { __typename?: 'Mutation', removePlayerFromGame: boolean };

export type CreateGameMutationVariables = Exact<{
  data: CreateGameInput;
}>;


export type CreateGameMutation = { __typename?: 'Mutation', createGame: { __typename?: 'Game', id: string } };

export type UpdateGameMutationVariables = Exact<{
  data: UpdateGameInput;
}>;


export type UpdateGameMutation = { __typename?: 'Mutation', updateGame: { __typename?: 'Game', id: string } };

export type GetHistoricalGamesQueryVariables = Exact<{
  input: GetHistoricalGameInput;
}>;


export type GetHistoricalGamesQuery = { __typename?: 'Query', getHistoricalGames: { __typename?: 'GetHistoricalGamesResponse', totalGames: number, games: Array<{ __typename?: 'Game', id: string, name: string, date: any }> } };

export type GetGameQueryVariables = Exact<{
  gameId: Scalars['String'];
}>;


export type GetGameQuery = { __typename?: 'Query', getGame?: { __typename?: 'Game', id: string, date: any, status: GamesStatus, name: string, maxPlayers: number, minPlayers: number, finalisedDate?: any | null, mvpPlayer?: { __typename?: 'Player', id: string, name: string } | null, reviews: Array<{ __typename?: 'PostGameReview', id: string, goals: number, highlight?: string | null, win: boolean, teamRating: number, createdAt: any, mvpVotePlayer: { __typename?: 'Player', id: string, name: string }, player: { __typename?: 'Player', id: string, name: string } }>, venue: { __typename?: 'Venue', id: string, name: string, active: boolean, address?: string | null, logo?: string | null, phone?: string | null }, attendance: Array<{ __typename?: 'Attendance', id: string, player: { __typename?: 'Player', id: string, name: string } }> } | null };

export type FinaliseGameMutationVariables = Exact<{
  input: FinaliseGameInputs;
}>;


export type FinaliseGameMutation = { __typename?: 'Mutation', finaliseGame: boolean };

export type DeleteGameMutationVariables = Exact<{
  gameId: Scalars['String'];
}>;


export type DeleteGameMutation = { __typename?: 'Mutation', deleteGame: { __typename?: 'Game', id: string } };

export type EmailLoginMutationVariables = Exact<{
  password: Scalars['String'];
  email: Scalars['String'];
}>;


export type EmailLoginMutation = { __typename?: 'Mutation', emailLogin: { __typename?: 'LoginResponse', accessToken: string } };

export type GoogleLoginMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type GoogleLoginMutation = { __typename?: 'Mutation', googleLogin: { __typename?: 'LoginResponse', accessToken: string } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type ConfirmEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ConfirmEmailMutation = { __typename?: 'Mutation', confirmEmail: boolean };

export type ResendConfirmationEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendConfirmationEmailMutation = { __typename?: 'Mutation', resendConfirmationEmail: { __typename?: 'LoginResponse', accessToken: string } };

export type RequestToJoinTeamMutationVariables = Exact<{
  teamCode: Scalars['String'];
}>;


export type RequestToJoinTeamMutation = { __typename?: 'Mutation', requestToJoinTeam: boolean };

export type GetTeamJoinRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamJoinRequestsQuery = { __typename?: 'Query', getTeamJoinRequests: Array<{ __typename?: 'JoinRequest', id: string, status: TeamJoinRequestStatus, createdAt: any, updatedAt: any, player?: { __typename?: 'Player', id: string, name: string, email: string } | null, team?: { __typename?: 'Team', id: string, name: string } | null, createdByPlayer?: { __typename?: 'Player', id: string, name: string } | null, updatedByPlayer?: { __typename?: 'Player', id: string, name: string } | null }> };

export type AcceptJoinRequestMutationVariables = Exact<{
  joinRequestId: Scalars['String'];
}>;


export type AcceptJoinRequestMutation = { __typename?: 'Mutation', acceptJoinRequest: boolean };

export type RejectJoinRequestMutationVariables = Exact<{
  joinRequestId: Scalars['String'];
}>;


export type RejectJoinRequestMutation = { __typename?: 'Mutation', rejectJoinRequest: boolean };

export type InitialiseSubscriptionMutationVariables = Exact<{
  pricingId: Scalars['String'];
}>;


export type InitialiseSubscriptionMutation = { __typename?: 'Mutation', initialiseSubscription: { __typename?: 'CreateSubscriptionResponse', subscriptionID: string, clientSecret: string } };

export type GetTeamSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamSubscriptionQuery = { __typename?: 'Query', getTeamSubscription?: { __typename?: 'Subscription', id: string, status: SubscriptionStatus, current_period_end: any, product?: { __typename?: 'Product', id: string, name: string, description: string, status: ProductStatus, pricing: Array<{ __typename?: 'Pricing', id: string, currency: string, amountCents: number, interval?: SubscriptionInterval | null }> } | null, card?: { __typename?: 'PaymentCard', brand: string, last4: string } | null } | null };

export type GetTeamPaymentPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamPaymentPlansQuery = { __typename?: 'Query', getTeamPaymentPlans: Array<{ __typename?: 'Product', id: string, name: string, description: string, status: ProductStatus, pricing: Array<{ __typename?: 'Pricing', id: string, amountCents: number, currency: string, interval?: SubscriptionInterval | null }> }> };

export type CancelTeamSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelTeamSubscriptionMutation = { __typename?: 'Mutation', cancelSubscription: boolean };

export type TakeMoneyMutationVariables = Exact<{
  input: TakeMoneyInput;
}>;


export type TakeMoneyMutation = { __typename?: 'Mutation', takeMoney: { __typename?: 'Transaction', id: string } };

export type SpendMoneyMutationVariables = Exact<{
  input: SpendMoneyInput;
}>;


export type SpendMoneyMutation = { __typename?: 'Mutation', spendMoney: Array<{ __typename?: 'Transaction', id: string }> };

export type GetBalanceTopupPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBalanceTopupPlansQuery = { __typename?: 'Query', getBalanceTopupPlans: Array<{ __typename?: 'Product', id: string, name: string, description: string, status: ProductStatus, pricing: Array<{ __typename?: 'Pricing', id: string, currency: string, amountCents: number }> }> };

export type InitTopupPaymentMutationVariables = Exact<{
  pricingId: Scalars['String'];
}>;


export type InitTopupPaymentMutation = { __typename?: 'Mutation', initialiseTopupPayment: { __typename?: 'initialiseTopupPaymentResponse', clientSecret: string } };

export type GetPlayersOnTeamQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlayersOnTeamQuery = { __typename?: 'Query', getPlayersOnTeam: Array<{ __typename?: 'Player', id: string, name: string, status: PlayerStatus, teamProfile?: { __typename?: 'TeamProfile', id: string, isTeamAdmin: boolean, picture?: string | null, position?: string | null, status: PlayerTeamsStatus } | null }> };

export type UpdatePlayersTeamProfileMutationVariables = Exact<{
  updatePlayersTeamProfileInput: UpdatePlayerTeamInput;
}>;


export type UpdatePlayersTeamProfileMutation = { __typename?: 'Mutation', updatePlayersTeamProfile: boolean };

export type RemovePlayerFromTeamMutationVariables = Exact<{
  teamProfileID: Scalars['String'];
}>;


export type RemovePlayerFromTeamMutation = { __typename?: 'Mutation', removePlayerFromTeam: boolean };

export type GetMyPendingJoinRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyPendingJoinRequestsQuery = { __typename?: 'Query', getMyPendingJoinRequests: Array<{ __typename?: 'JoinRequest', id: string, status: TeamJoinRequestStatus, createdAt: any, team?: { __typename?: 'Team', name: string } | null }> };

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyProfileQuery = { __typename?: 'Query', getMyProfile: { __typename?: 'Profile', authProvider: AuthProviders, emailConfirmed: boolean, totalGames: number, totalGamesWon: number, totalMvpVotes: number, totalGoals: number, averageGoalsPerMatch: number, player: { __typename?: 'Player', id: string, name: string, email: string }, teamProfile?: { __typename?: 'TeamProfile', position?: string | null, picture?: string | null, isTeamAdmin: boolean, hasJoinedTeamChat: boolean, team: { __typename?: 'Team', id: string, name: string, image?: string | null, description: string, whatsappJoinLink?: string | null, owner: { __typename?: 'Player', id: string, name: string } } } | null, teams: Array<{ __typename?: 'Team', id: string, name: string }> } };

export type GetProfileQueryVariables = Exact<{
  playerID: Scalars['String'];
}>;


export type GetProfileQuery = { __typename?: 'Query', getPlayersProfile: { __typename?: 'Profile', totalGames: number, totalGamesWon: number, totalMvpVotes: number, totalGoals: number, averageGoalsPerMatch: number, totalMvpWins: number, player: { __typename?: 'Player', id: string, name: string }, teamProfile?: { __typename?: 'TeamProfile', position?: string | null, picture?: string | null, isTeamAdmin: boolean, hasJoinedTeamChat: boolean, team: { __typename?: 'Team', id: string, name: string, image?: string | null, owner: { __typename?: 'Player', id: string, name: string } } } | null, teams: Array<{ __typename?: 'Team', id: string, name: string, whatsappJoinLink?: string | null }>, recentReviews: Array<{ __typename?: 'PostGameReview', id: string, highlight?: string | null, win: boolean, goals: number, createdAt: any }>, recentTransactions: Array<{ __typename?: 'Transaction', id: string, name: string, createdAt: any, amount: number, type: TransactionType }> } };

export type UpdatePlayerMutationVariables = Exact<{
  inputs: UpdatePlayerInputs;
}>;


export type UpdatePlayerMutation = { __typename?: 'Mutation', updatePlayer: { __typename?: 'Player', id: string, name: string, email: string } };

export type GetNotificationsQueryVariables = Exact<{
  inputs: GetNotificationInputs;
}>;


export type GetNotificationsQuery = { __typename?: 'Query', getNotifications: Array<{ __typename?: 'Notification', id: string, priority: NotificationPriority, isRead: boolean, message: string, redirect?: string | null, createdAt: any, deletedAt?: any | null, player?: { __typename?: 'Player', id: string, name: string } | null }> };

export type MarkNotificationsReadMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkNotificationsReadMutation = { __typename?: 'Mutation', markAllRead: boolean };

export type CashOutRequestMutationVariables = Exact<{
  input: CashOutRequestInput;
}>;


export type CashOutRequestMutation = { __typename?: 'Mutation', cashOutRequest: boolean };

export type ToggleActiveTeamMutationVariables = Exact<{
  newTeamID: Scalars['String'];
}>;


export type ToggleActiveTeamMutation = { __typename?: 'Mutation', toggleActiveTeam: boolean };

export type GetActiveTeamQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveTeamQuery = { __typename?: 'Query', getPlayersActiveTeamProfile?: { __typename?: 'TeamProfile', team: { __typename?: 'Team', id: string, name: string } } | null };

export type GetPlayersTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlayersTeamsQuery = { __typename?: 'Query', getPlayersTeams: Array<{ __typename?: 'TeamProfile', id: string, isTeamAdmin: boolean, picture?: string | null, position?: string | null, status: PlayerTeamsStatus, team: { __typename?: 'Team', id: string, name: string, active: boolean, description: string, whatsappJoinLink?: string | null, joinCode: string, owner: { __typename?: 'Player', id: string, name: string } } }> };

export type CreateTeamMutationVariables = Exact<{
  input: CreateTeamInput;
}>;


export type CreateTeamMutation = { __typename?: 'Mutation', createTeam: { __typename?: 'Team', id: string } };

export type UpdateTeamMutationVariables = Exact<{
  input: UpdateTeamInput;
}>;


export type UpdateTeamMutation = { __typename?: 'Mutation', updateTeam: { __typename?: 'Team', id: string } };

export type GetTeamQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;


export type GetTeamQuery = { __typename?: 'Query', getTeam?: { __typename?: 'Team', id: string, active: boolean, name: string, description: string, whatsappJoinLink?: string | null, joinCode: string, image?: string | null, venues: Array<{ __typename?: 'Venue', id: string, name: string, active: boolean, logo?: string | null, phone?: string | null, website?: string | null, address?: string | null }> } | null };

export type GetTeamConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamConfigQuery = { __typename?: 'Query', getTeamConfig: Array<{ __typename?: 'TeamConfig', id: string, configValueType: TeamConfigType, configItem: TeamConfigItem, configValue: string, deletedAt?: any | null, createdAt: any, updatedAt: any, deletedByPlayer?: { __typename?: 'Player', id: string, name: string } | null, createdByPlayer?: { __typename?: 'Player', id: string, name: string } | null, updatedByPlayer?: { __typename?: 'Player', id: string, name: string } | null }> };

export type GetTeamRulesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamRulesQuery = { __typename?: 'Query', getTeamRules: Array<{ __typename?: 'Rule', id: string, ruleTitle: string, ruleDetails: string, createdAt: any, updatedAt: any, team: { __typename?: 'Team', id: string, name: string }, createdByPlayer?: { __typename?: 'Player', id: string, name: string } | null, updatedByPlayer?: { __typename?: 'Player', id: string, name: string } | null }> };

export type CreateTeamRuleMutationVariables = Exact<{
  input: CreateTeamRuleInput;
}>;


export type CreateTeamRuleMutation = { __typename?: 'Mutation', createTeamRule: { __typename?: 'Rule', id: string } };

export type UpdateTeamRuleMutationVariables = Exact<{
  input: EditTeamRuleInput;
}>;


export type UpdateTeamRuleMutation = { __typename?: 'Mutation', updateTeamRule: { __typename?: 'Rule', id: string } };

export type DeleteTeamRuleMutationVariables = Exact<{
  ruleId: Scalars['String'];
}>;


export type DeleteTeamRuleMutation = { __typename?: 'Mutation', deleteTeamRule: boolean };

export type InvitePlayerToJoinTeamMutationVariables = Exact<{
  input: InvitePlayerInputs;
}>;


export type InvitePlayerToJoinTeamMutation = { __typename?: 'Mutation', invitePlayerToJoinTeam: boolean };

export type GetMyBalanceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyBalanceQuery = { __typename?: 'Query', getPlayerBalance: number };

export type GetVenuesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVenuesQuery = { __typename?: 'Query', getVenues: Array<{ __typename?: 'Venue', id: string, name: string, active: boolean, address?: string | null, postcode?: number | null, website?: string | null, logo?: string | null, phone?: string | null }> };

export type CreateVenueMutationVariables = Exact<{
  data: CreateVenueArgs;
}>;


export type CreateVenueMutation = { __typename?: 'Mutation', createVenue: { __typename?: 'Venue', id: string } };

export type UpdateVenueMutationVariables = Exact<{
  data: UpdateVenueArgs;
}>;


export type UpdateVenueMutation = { __typename?: 'Mutation', updateVenue: { __typename?: 'Venue', id: string } };

export type DeleteVenueMutationVariables = Exact<{
  deleteVenueId: Scalars['String'];
}>;


export type DeleteVenueMutation = { __typename?: 'Mutation', deleteVenue: boolean };

export type LookupPlacePredictionsQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type LookupPlacePredictionsQuery = { __typename?: 'Query', lookupPlacePredictions: Array<{ __typename?: 'PlacePredictionResult', description: string, distance_meters?: number | null, place_id: string, types: Array<string>, terms: Array<{ __typename?: 'PredictionTerm', offset: string, value: string }>, matched_substrings: Array<{ __typename?: 'PredictionSubstring', length: number, offset: number }>, structured_formatting: { __typename?: 'StructuredFormatting', main_text: string, secondary_text: string, main_text_matched_substrings: Array<{ __typename?: 'PredictionSubstring', offset: number, length: number }>, secondary_text_matched_substrings?: Array<{ __typename?: 'PredictionSubstring', length: number, offset: number }> | null } }> };

export type LookupPlaceDetailsQueryVariables = Exact<{
  placeid: Scalars['String'];
}>;


export type LookupPlaceDetailsQuery = { __typename?: 'Query', lookupPlace: { __typename?: 'PlaceDetails', name?: string | null, formatted_address?: string | null, formatted_phone_number?: string | null, website?: string | null, rating?: number | null, types?: Array<string> | null, postcode?: string | null, lat?: number | null, lng?: number | null } };

export type GoogleRegisterMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type GoogleRegisterMutation = { __typename?: 'Mutation', googleRegister: { __typename?: 'LoginResponse', accessToken: string } };

export type EmailRegisterMutationVariables = Exact<{
  input: EmailRegisterInput;
}>;


export type EmailRegisterMutation = { __typename?: 'Mutation', emailRegister: { __typename?: 'LoginResponse', accessToken: string } };


export const GetReoccuringGamesDocument = gql`
    query GetReoccuringGames {
  getReoccuringGames {
    id
    name
    status
    frequency
    minPlayers
    maxPlayers
    gameDay
    gameTime
    venue {
      id
      name
    }
  }
}
    `;

/**
 * __useGetReoccuringGamesQuery__
 *
 * To run a query within a React component, call `useGetReoccuringGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReoccuringGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReoccuringGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReoccuringGamesQuery(baseOptions?: Apollo.QueryHookOptions<GetReoccuringGamesQuery, GetReoccuringGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReoccuringGamesQuery, GetReoccuringGamesQueryVariables>(GetReoccuringGamesDocument, options);
      }
export function useGetReoccuringGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReoccuringGamesQuery, GetReoccuringGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReoccuringGamesQuery, GetReoccuringGamesQueryVariables>(GetReoccuringGamesDocument, options);
        }
export type GetReoccuringGamesQueryHookResult = ReturnType<typeof useGetReoccuringGamesQuery>;
export type GetReoccuringGamesLazyQueryHookResult = ReturnType<typeof useGetReoccuringGamesLazyQuery>;
export type GetReoccuringGamesQueryResult = Apollo.QueryResult<GetReoccuringGamesQuery, GetReoccuringGamesQueryVariables>;
export const CreateReoccuringGameDocument = gql`
    mutation CreateReoccuringGame($input: CreateReoccuringGameInput!) {
  createReoccuringGame(input: $input) {
    id
  }
}
    `;
export type CreateReoccuringGameMutationFn = Apollo.MutationFunction<CreateReoccuringGameMutation, CreateReoccuringGameMutationVariables>;

/**
 * __useCreateReoccuringGameMutation__
 *
 * To run a mutation, you first call `useCreateReoccuringGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReoccuringGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReoccuringGameMutation, { data, loading, error }] = useCreateReoccuringGameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReoccuringGameMutation(baseOptions?: Apollo.MutationHookOptions<CreateReoccuringGameMutation, CreateReoccuringGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReoccuringGameMutation, CreateReoccuringGameMutationVariables>(CreateReoccuringGameDocument, options);
      }
export type CreateReoccuringGameMutationHookResult = ReturnType<typeof useCreateReoccuringGameMutation>;
export type CreateReoccuringGameMutationResult = Apollo.MutationResult<CreateReoccuringGameMutation>;
export type CreateReoccuringGameMutationOptions = Apollo.BaseMutationOptions<CreateReoccuringGameMutation, CreateReoccuringGameMutationVariables>;
export const UpdateReoccuringGameDocument = gql`
    mutation UpdateReoccuringGame($input: UpdateReoccuringGameInput!) {
  updateReoccuringGame(input: $input) {
    id
  }
}
    `;
export type UpdateReoccuringGameMutationFn = Apollo.MutationFunction<UpdateReoccuringGameMutation, UpdateReoccuringGameMutationVariables>;

/**
 * __useUpdateReoccuringGameMutation__
 *
 * To run a mutation, you first call `useUpdateReoccuringGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReoccuringGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReoccuringGameMutation, { data, loading, error }] = useUpdateReoccuringGameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReoccuringGameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReoccuringGameMutation, UpdateReoccuringGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReoccuringGameMutation, UpdateReoccuringGameMutationVariables>(UpdateReoccuringGameDocument, options);
      }
export type UpdateReoccuringGameMutationHookResult = ReturnType<typeof useUpdateReoccuringGameMutation>;
export type UpdateReoccuringGameMutationResult = Apollo.MutationResult<UpdateReoccuringGameMutation>;
export type UpdateReoccuringGameMutationOptions = Apollo.BaseMutationOptions<UpdateReoccuringGameMutation, UpdateReoccuringGameMutationVariables>;
export const DeleteReoccuringGameDocument = gql`
    mutation DeleteReoccuringGame($deleteReoccuringGameId: String!) {
  deleteReoccuringGame(id: $deleteReoccuringGameId)
}
    `;
export type DeleteReoccuringGameMutationFn = Apollo.MutationFunction<DeleteReoccuringGameMutation, DeleteReoccuringGameMutationVariables>;

/**
 * __useDeleteReoccuringGameMutation__
 *
 * To run a mutation, you first call `useDeleteReoccuringGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReoccuringGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReoccuringGameMutation, { data, loading, error }] = useDeleteReoccuringGameMutation({
 *   variables: {
 *      deleteReoccuringGameId: // value for 'deleteReoccuringGameId'
 *   },
 * });
 */
export function useDeleteReoccuringGameMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReoccuringGameMutation, DeleteReoccuringGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReoccuringGameMutation, DeleteReoccuringGameMutationVariables>(DeleteReoccuringGameDocument, options);
      }
export type DeleteReoccuringGameMutationHookResult = ReturnType<typeof useDeleteReoccuringGameMutation>;
export type DeleteReoccuringGameMutationResult = Apollo.MutationResult<DeleteReoccuringGameMutation>;
export type DeleteReoccuringGameMutationOptions = Apollo.BaseMutationOptions<DeleteReoccuringGameMutation, DeleteReoccuringGameMutationVariables>;
export const GetRecentlyFinalisedGamesDocument = gql`
    query GetRecentlyFinalisedGames {
  getRecentlyFinalisedGames {
    game {
      id
      date
      name
      status
      finalisedDate
      mvpPlayer {
        id
        name
      }
      venue {
        id
        name
      }
      attendance {
        player {
          id
          name
        }
      }
    }
    review {
      id
      goals
      highlight
      mvpVotePlayer {
        id
        name
      }
      updatedAt
    }
  }
}
    `;

/**
 * __useGetRecentlyFinalisedGamesQuery__
 *
 * To run a query within a React component, call `useGetRecentlyFinalisedGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentlyFinalisedGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentlyFinalisedGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentlyFinalisedGamesQuery(baseOptions?: Apollo.QueryHookOptions<GetRecentlyFinalisedGamesQuery, GetRecentlyFinalisedGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecentlyFinalisedGamesQuery, GetRecentlyFinalisedGamesQueryVariables>(GetRecentlyFinalisedGamesDocument, options);
      }
export function useGetRecentlyFinalisedGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentlyFinalisedGamesQuery, GetRecentlyFinalisedGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecentlyFinalisedGamesQuery, GetRecentlyFinalisedGamesQueryVariables>(GetRecentlyFinalisedGamesDocument, options);
        }
export type GetRecentlyFinalisedGamesQueryHookResult = ReturnType<typeof useGetRecentlyFinalisedGamesQuery>;
export type GetRecentlyFinalisedGamesLazyQueryHookResult = ReturnType<typeof useGetRecentlyFinalisedGamesLazyQuery>;
export type GetRecentlyFinalisedGamesQueryResult = Apollo.QueryResult<GetRecentlyFinalisedGamesQuery, GetRecentlyFinalisedGamesQueryVariables>;
export const CreatePostGameReviewDocument = gql`
    mutation CreatePostGameReview($data: CreatePostGameReviewInput!) {
  createPostGameReview(data: $data)
}
    `;
export type CreatePostGameReviewMutationFn = Apollo.MutationFunction<CreatePostGameReviewMutation, CreatePostGameReviewMutationVariables>;

/**
 * __useCreatePostGameReviewMutation__
 *
 * To run a mutation, you first call `useCreatePostGameReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostGameReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostGameReviewMutation, { data, loading, error }] = useCreatePostGameReviewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePostGameReviewMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostGameReviewMutation, CreatePostGameReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostGameReviewMutation, CreatePostGameReviewMutationVariables>(CreatePostGameReviewDocument, options);
      }
export type CreatePostGameReviewMutationHookResult = ReturnType<typeof useCreatePostGameReviewMutation>;
export type CreatePostGameReviewMutationResult = Apollo.MutationResult<CreatePostGameReviewMutation>;
export type CreatePostGameReviewMutationOptions = Apollo.BaseMutationOptions<CreatePostGameReviewMutation, CreatePostGameReviewMutationVariables>;
export const UpdatePostGameReviewDocument = gql`
    mutation UpdatePostGameReview($data: UpdatePostGameReviewInput!) {
  updatePostGameReview(data: $data)
}
    `;
export type UpdatePostGameReviewMutationFn = Apollo.MutationFunction<UpdatePostGameReviewMutation, UpdatePostGameReviewMutationVariables>;

/**
 * __useUpdatePostGameReviewMutation__
 *
 * To run a mutation, you first call `useUpdatePostGameReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostGameReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostGameReviewMutation, { data, loading, error }] = useUpdatePostGameReviewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePostGameReviewMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostGameReviewMutation, UpdatePostGameReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostGameReviewMutation, UpdatePostGameReviewMutationVariables>(UpdatePostGameReviewDocument, options);
      }
export type UpdatePostGameReviewMutationHookResult = ReturnType<typeof useUpdatePostGameReviewMutation>;
export type UpdatePostGameReviewMutationResult = Apollo.MutationResult<UpdatePostGameReviewMutation>;
export type UpdatePostGameReviewMutationOptions = Apollo.BaseMutationOptions<UpdatePostGameReviewMutation, UpdatePostGameReviewMutationVariables>;
export const GetPostGameReviewDocument = gql`
    query GetPostGameReview($reviewId: String!) {
  getPostGameReview(reviewID: $reviewId) {
    id
    player {
      id
      name
    }
    mvpVotePlayer {
      id
      name
    }
    goals
    win
    highlight
    teamRating
    game {
      id
      name
      attendance {
        id
        player {
          id
          name
        }
      }
    }
    team {
      id
      name
    }
    mvpVotePlayer {
      id
      name
    }
  }
}
    `;

/**
 * __useGetPostGameReviewQuery__
 *
 * To run a query within a React component, call `useGetPostGameReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostGameReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostGameReviewQuery({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *   },
 * });
 */
export function useGetPostGameReviewQuery(baseOptions: Apollo.QueryHookOptions<GetPostGameReviewQuery, GetPostGameReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostGameReviewQuery, GetPostGameReviewQueryVariables>(GetPostGameReviewDocument, options);
      }
export function useGetPostGameReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostGameReviewQuery, GetPostGameReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostGameReviewQuery, GetPostGameReviewQueryVariables>(GetPostGameReviewDocument, options);
        }
export type GetPostGameReviewQueryHookResult = ReturnType<typeof useGetPostGameReviewQuery>;
export type GetPostGameReviewLazyQueryHookResult = ReturnType<typeof useGetPostGameReviewLazyQuery>;
export type GetPostGameReviewQueryResult = Apollo.QueryResult<GetPostGameReviewQuery, GetPostGameReviewQueryVariables>;
export const GetUpcomingGamesDocument = gql`
    query GetUpcomingGames {
  getUpcomingGames {
    id
    date
    name
    maxPlayers
    minPlayers
    status
    weather {
      precis
      precisCode
      rainfallProbability
      gameTemp
      min
      max
    }
    attendance {
      id
      player {
        id
        name
        teamProfile {
          position
          picture
        }
      }
    }
    venue {
      id
      name
      logo
      address
      phone
      website
    }
  }
}
    `;

/**
 * __useGetUpcomingGamesQuery__
 *
 * To run a query within a React component, call `useGetUpcomingGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUpcomingGamesQuery(baseOptions?: Apollo.QueryHookOptions<GetUpcomingGamesQuery, GetUpcomingGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpcomingGamesQuery, GetUpcomingGamesQueryVariables>(GetUpcomingGamesDocument, options);
      }
export function useGetUpcomingGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpcomingGamesQuery, GetUpcomingGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpcomingGamesQuery, GetUpcomingGamesQueryVariables>(GetUpcomingGamesDocument, options);
        }
export type GetUpcomingGamesQueryHookResult = ReturnType<typeof useGetUpcomingGamesQuery>;
export type GetUpcomingGamesLazyQueryHookResult = ReturnType<typeof useGetUpcomingGamesLazyQuery>;
export type GetUpcomingGamesQueryResult = Apollo.QueryResult<GetUpcomingGamesQuery, GetUpcomingGamesQueryVariables>;
export const AddPlayerToGameDocument = gql`
    mutation AddPlayerToGame($gameId: String!, $playerId: String!) {
  addPlayerToGame(gameID: $gameId, playerID: $playerId)
}
    `;
export type AddPlayerToGameMutationFn = Apollo.MutationFunction<AddPlayerToGameMutation, AddPlayerToGameMutationVariables>;

/**
 * __useAddPlayerToGameMutation__
 *
 * To run a mutation, you first call `useAddPlayerToGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlayerToGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlayerToGameMutation, { data, loading, error }] = useAddPlayerToGameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useAddPlayerToGameMutation(baseOptions?: Apollo.MutationHookOptions<AddPlayerToGameMutation, AddPlayerToGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPlayerToGameMutation, AddPlayerToGameMutationVariables>(AddPlayerToGameDocument, options);
      }
export type AddPlayerToGameMutationHookResult = ReturnType<typeof useAddPlayerToGameMutation>;
export type AddPlayerToGameMutationResult = Apollo.MutationResult<AddPlayerToGameMutation>;
export type AddPlayerToGameMutationOptions = Apollo.BaseMutationOptions<AddPlayerToGameMutation, AddPlayerToGameMutationVariables>;
export const RemovePlayerFromGameDocument = gql`
    mutation RemovePlayerFromGame($playerId: String!, $gameId: String!) {
  removePlayerFromGame(playerID: $playerId, gameID: $gameId)
}
    `;
export type RemovePlayerFromGameMutationFn = Apollo.MutationFunction<RemovePlayerFromGameMutation, RemovePlayerFromGameMutationVariables>;

/**
 * __useRemovePlayerFromGameMutation__
 *
 * To run a mutation, you first call `useRemovePlayerFromGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePlayerFromGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePlayerFromGameMutation, { data, loading, error }] = useRemovePlayerFromGameMutation({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useRemovePlayerFromGameMutation(baseOptions?: Apollo.MutationHookOptions<RemovePlayerFromGameMutation, RemovePlayerFromGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePlayerFromGameMutation, RemovePlayerFromGameMutationVariables>(RemovePlayerFromGameDocument, options);
      }
export type RemovePlayerFromGameMutationHookResult = ReturnType<typeof useRemovePlayerFromGameMutation>;
export type RemovePlayerFromGameMutationResult = Apollo.MutationResult<RemovePlayerFromGameMutation>;
export type RemovePlayerFromGameMutationOptions = Apollo.BaseMutationOptions<RemovePlayerFromGameMutation, RemovePlayerFromGameMutationVariables>;
export const CreateGameDocument = gql`
    mutation CreateGame($data: CreateGameInput!) {
  createGame(data: $data) {
    id
  }
}
    `;
export type CreateGameMutationFn = Apollo.MutationFunction<CreateGameMutation, CreateGameMutationVariables>;

/**
 * __useCreateGameMutation__
 *
 * To run a mutation, you first call `useCreateGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameMutation, { data, loading, error }] = useCreateGameMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateGameMutation(baseOptions?: Apollo.MutationHookOptions<CreateGameMutation, CreateGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGameMutation, CreateGameMutationVariables>(CreateGameDocument, options);
      }
export type CreateGameMutationHookResult = ReturnType<typeof useCreateGameMutation>;
export type CreateGameMutationResult = Apollo.MutationResult<CreateGameMutation>;
export type CreateGameMutationOptions = Apollo.BaseMutationOptions<CreateGameMutation, CreateGameMutationVariables>;
export const UpdateGameDocument = gql`
    mutation UpdateGame($data: UpdateGameInput!) {
  updateGame(data: $data) {
    id
  }
}
    `;
export type UpdateGameMutationFn = Apollo.MutationFunction<UpdateGameMutation, UpdateGameMutationVariables>;

/**
 * __useUpdateGameMutation__
 *
 * To run a mutation, you first call `useUpdateGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameMutation, { data, loading, error }] = useUpdateGameMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateGameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGameMutation, UpdateGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGameMutation, UpdateGameMutationVariables>(UpdateGameDocument, options);
      }
export type UpdateGameMutationHookResult = ReturnType<typeof useUpdateGameMutation>;
export type UpdateGameMutationResult = Apollo.MutationResult<UpdateGameMutation>;
export type UpdateGameMutationOptions = Apollo.BaseMutationOptions<UpdateGameMutation, UpdateGameMutationVariables>;
export const GetHistoricalGamesDocument = gql`
    query GetHistoricalGames($input: GetHistoricalGameInput!) {
  getHistoricalGames(input: $input) {
    games {
      id
      name
      date
    }
    totalGames
  }
}
    `;

/**
 * __useGetHistoricalGamesQuery__
 *
 * To run a query within a React component, call `useGetHistoricalGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoricalGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoricalGamesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHistoricalGamesQuery(baseOptions: Apollo.QueryHookOptions<GetHistoricalGamesQuery, GetHistoricalGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHistoricalGamesQuery, GetHistoricalGamesQueryVariables>(GetHistoricalGamesDocument, options);
      }
export function useGetHistoricalGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHistoricalGamesQuery, GetHistoricalGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHistoricalGamesQuery, GetHistoricalGamesQueryVariables>(GetHistoricalGamesDocument, options);
        }
export type GetHistoricalGamesQueryHookResult = ReturnType<typeof useGetHistoricalGamesQuery>;
export type GetHistoricalGamesLazyQueryHookResult = ReturnType<typeof useGetHistoricalGamesLazyQuery>;
export type GetHistoricalGamesQueryResult = Apollo.QueryResult<GetHistoricalGamesQuery, GetHistoricalGamesQueryVariables>;
export const GetGameDocument = gql`
    query GetGame($gameId: String!) {
  getGame(gameID: $gameId) {
    id
    date
    status
    name
    maxPlayers
    minPlayers
    mvpPlayer {
      id
      name
    }
    finalisedDate
    reviews {
      id
      goals
      highlight
      win
      teamRating
      createdAt
      mvpVotePlayer {
        id
        name
      }
      player {
        id
        name
      }
    }
    venue {
      id
      name
      active
      address
      logo
      phone
    }
    mvpPlayer {
      id
    }
    attendance {
      id
      player {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetGameQuery__
 *
 * To run a query within a React component, call `useGetGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useGetGameQuery(baseOptions: Apollo.QueryHookOptions<GetGameQuery, GetGameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGameQuery, GetGameQueryVariables>(GetGameDocument, options);
      }
export function useGetGameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGameQuery, GetGameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGameQuery, GetGameQueryVariables>(GetGameDocument, options);
        }
export type GetGameQueryHookResult = ReturnType<typeof useGetGameQuery>;
export type GetGameLazyQueryHookResult = ReturnType<typeof useGetGameLazyQuery>;
export type GetGameQueryResult = Apollo.QueryResult<GetGameQuery, GetGameQueryVariables>;
export const FinaliseGameDocument = gql`
    mutation FinaliseGame($input: FinaliseGameInputs!) {
  finaliseGame(input: $input)
}
    `;
export type FinaliseGameMutationFn = Apollo.MutationFunction<FinaliseGameMutation, FinaliseGameMutationVariables>;

/**
 * __useFinaliseGameMutation__
 *
 * To run a mutation, you first call `useFinaliseGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinaliseGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finaliseGameMutation, { data, loading, error }] = useFinaliseGameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinaliseGameMutation(baseOptions?: Apollo.MutationHookOptions<FinaliseGameMutation, FinaliseGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinaliseGameMutation, FinaliseGameMutationVariables>(FinaliseGameDocument, options);
      }
export type FinaliseGameMutationHookResult = ReturnType<typeof useFinaliseGameMutation>;
export type FinaliseGameMutationResult = Apollo.MutationResult<FinaliseGameMutation>;
export type FinaliseGameMutationOptions = Apollo.BaseMutationOptions<FinaliseGameMutation, FinaliseGameMutationVariables>;
export const DeleteGameDocument = gql`
    mutation DeleteGame($gameId: String!) {
  deleteGame(gameID: $gameId) {
    id
  }
}
    `;
export type DeleteGameMutationFn = Apollo.MutationFunction<DeleteGameMutation, DeleteGameMutationVariables>;

/**
 * __useDeleteGameMutation__
 *
 * To run a mutation, you first call `useDeleteGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGameMutation, { data, loading, error }] = useDeleteGameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useDeleteGameMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGameMutation, DeleteGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGameMutation, DeleteGameMutationVariables>(DeleteGameDocument, options);
      }
export type DeleteGameMutationHookResult = ReturnType<typeof useDeleteGameMutation>;
export type DeleteGameMutationResult = Apollo.MutationResult<DeleteGameMutation>;
export type DeleteGameMutationOptions = Apollo.BaseMutationOptions<DeleteGameMutation, DeleteGameMutationVariables>;
export const EmailLoginDocument = gql`
    mutation EmailLogin($password: String!, $email: String!) {
  emailLogin(password: $password, email: $email) {
    accessToken
  }
}
    `;
export type EmailLoginMutationFn = Apollo.MutationFunction<EmailLoginMutation, EmailLoginMutationVariables>;

/**
 * __useEmailLoginMutation__
 *
 * To run a mutation, you first call `useEmailLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailLoginMutation, { data, loading, error }] = useEmailLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEmailLoginMutation(baseOptions?: Apollo.MutationHookOptions<EmailLoginMutation, EmailLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailLoginMutation, EmailLoginMutationVariables>(EmailLoginDocument, options);
      }
export type EmailLoginMutationHookResult = ReturnType<typeof useEmailLoginMutation>;
export type EmailLoginMutationResult = Apollo.MutationResult<EmailLoginMutation>;
export type EmailLoginMutationOptions = Apollo.BaseMutationOptions<EmailLoginMutation, EmailLoginMutationVariables>;
export const GoogleLoginDocument = gql`
    mutation GoogleLogin($token: String!) {
  googleLogin(token: $token) {
    accessToken
  }
}
    `;
export type GoogleLoginMutationFn = Apollo.MutationFunction<GoogleLoginMutation, GoogleLoginMutationVariables>;

/**
 * __useGoogleLoginMutation__
 *
 * To run a mutation, you first call `useGoogleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleLoginMutation, { data, loading, error }] = useGoogleLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGoogleLoginMutation(baseOptions?: Apollo.MutationHookOptions<GoogleLoginMutation, GoogleLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleLoginMutation, GoogleLoginMutationVariables>(GoogleLoginDocument, options);
      }
export type GoogleLoginMutationHookResult = ReturnType<typeof useGoogleLoginMutation>;
export type GoogleLoginMutationResult = Apollo.MutationResult<GoogleLoginMutation>;
export type GoogleLoginMutationOptions = Apollo.BaseMutationOptions<GoogleLoginMutation, GoogleLoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($token: String!) {
  confirmEmail(token: $token)
}
    `;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export const ResendConfirmationEmailDocument = gql`
    mutation ResendConfirmationEmail {
  resendConfirmationEmail {
    accessToken
  }
}
    `;
export type ResendConfirmationEmailMutationFn = Apollo.MutationFunction<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>;

/**
 * __useResendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useResendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationEmailMutation, { data, loading, error }] = useResendConfirmationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendConfirmationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>(ResendConfirmationEmailDocument, options);
      }
export type ResendConfirmationEmailMutationHookResult = ReturnType<typeof useResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationResult = Apollo.MutationResult<ResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationOptions = Apollo.BaseMutationOptions<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>;
export const RequestToJoinTeamDocument = gql`
    mutation RequestToJoinTeam($teamCode: String!) {
  requestToJoinTeam(teamCode: $teamCode)
}
    `;
export type RequestToJoinTeamMutationFn = Apollo.MutationFunction<RequestToJoinTeamMutation, RequestToJoinTeamMutationVariables>;

/**
 * __useRequestToJoinTeamMutation__
 *
 * To run a mutation, you first call `useRequestToJoinTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestToJoinTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestToJoinTeamMutation, { data, loading, error }] = useRequestToJoinTeamMutation({
 *   variables: {
 *      teamCode: // value for 'teamCode'
 *   },
 * });
 */
export function useRequestToJoinTeamMutation(baseOptions?: Apollo.MutationHookOptions<RequestToJoinTeamMutation, RequestToJoinTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestToJoinTeamMutation, RequestToJoinTeamMutationVariables>(RequestToJoinTeamDocument, options);
      }
export type RequestToJoinTeamMutationHookResult = ReturnType<typeof useRequestToJoinTeamMutation>;
export type RequestToJoinTeamMutationResult = Apollo.MutationResult<RequestToJoinTeamMutation>;
export type RequestToJoinTeamMutationOptions = Apollo.BaseMutationOptions<RequestToJoinTeamMutation, RequestToJoinTeamMutationVariables>;
export const GetTeamJoinRequestsDocument = gql`
    query GetTeamJoinRequests {
  getTeamJoinRequests {
    id
    status
    player {
      id
      name
      email
    }
    team {
      id
      name
    }
    createdByPlayer {
      id
      name
    }
    createdAt
    updatedByPlayer {
      id
      name
    }
    updatedAt
  }
}
    `;

/**
 * __useGetTeamJoinRequestsQuery__
 *
 * To run a query within a React component, call `useGetTeamJoinRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamJoinRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamJoinRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamJoinRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamJoinRequestsQuery, GetTeamJoinRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamJoinRequestsQuery, GetTeamJoinRequestsQueryVariables>(GetTeamJoinRequestsDocument, options);
      }
export function useGetTeamJoinRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamJoinRequestsQuery, GetTeamJoinRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamJoinRequestsQuery, GetTeamJoinRequestsQueryVariables>(GetTeamJoinRequestsDocument, options);
        }
export type GetTeamJoinRequestsQueryHookResult = ReturnType<typeof useGetTeamJoinRequestsQuery>;
export type GetTeamJoinRequestsLazyQueryHookResult = ReturnType<typeof useGetTeamJoinRequestsLazyQuery>;
export type GetTeamJoinRequestsQueryResult = Apollo.QueryResult<GetTeamJoinRequestsQuery, GetTeamJoinRequestsQueryVariables>;
export const AcceptJoinRequestDocument = gql`
    mutation AcceptJoinRequest($joinRequestId: String!) {
  acceptJoinRequest(joinRequestID: $joinRequestId)
}
    `;
export type AcceptJoinRequestMutationFn = Apollo.MutationFunction<AcceptJoinRequestMutation, AcceptJoinRequestMutationVariables>;

/**
 * __useAcceptJoinRequestMutation__
 *
 * To run a mutation, you first call `useAcceptJoinRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptJoinRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptJoinRequestMutation, { data, loading, error }] = useAcceptJoinRequestMutation({
 *   variables: {
 *      joinRequestId: // value for 'joinRequestId'
 *   },
 * });
 */
export function useAcceptJoinRequestMutation(baseOptions?: Apollo.MutationHookOptions<AcceptJoinRequestMutation, AcceptJoinRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptJoinRequestMutation, AcceptJoinRequestMutationVariables>(AcceptJoinRequestDocument, options);
      }
export type AcceptJoinRequestMutationHookResult = ReturnType<typeof useAcceptJoinRequestMutation>;
export type AcceptJoinRequestMutationResult = Apollo.MutationResult<AcceptJoinRequestMutation>;
export type AcceptJoinRequestMutationOptions = Apollo.BaseMutationOptions<AcceptJoinRequestMutation, AcceptJoinRequestMutationVariables>;
export const RejectJoinRequestDocument = gql`
    mutation RejectJoinRequest($joinRequestId: String!) {
  rejectJoinRequest(joinRequestID: $joinRequestId)
}
    `;
export type RejectJoinRequestMutationFn = Apollo.MutationFunction<RejectJoinRequestMutation, RejectJoinRequestMutationVariables>;

/**
 * __useRejectJoinRequestMutation__
 *
 * To run a mutation, you first call `useRejectJoinRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectJoinRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectJoinRequestMutation, { data, loading, error }] = useRejectJoinRequestMutation({
 *   variables: {
 *      joinRequestId: // value for 'joinRequestId'
 *   },
 * });
 */
export function useRejectJoinRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectJoinRequestMutation, RejectJoinRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectJoinRequestMutation, RejectJoinRequestMutationVariables>(RejectJoinRequestDocument, options);
      }
export type RejectJoinRequestMutationHookResult = ReturnType<typeof useRejectJoinRequestMutation>;
export type RejectJoinRequestMutationResult = Apollo.MutationResult<RejectJoinRequestMutation>;
export type RejectJoinRequestMutationOptions = Apollo.BaseMutationOptions<RejectJoinRequestMutation, RejectJoinRequestMutationVariables>;
export const InitialiseSubscriptionDocument = gql`
    mutation InitialiseSubscription($pricingId: String!) {
  initialiseSubscription(pricingID: $pricingId) {
    subscriptionID
    clientSecret
  }
}
    `;
export type InitialiseSubscriptionMutationFn = Apollo.MutationFunction<InitialiseSubscriptionMutation, InitialiseSubscriptionMutationVariables>;

/**
 * __useInitialiseSubscriptionMutation__
 *
 * To run a mutation, you first call `useInitialiseSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitialiseSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initialiseSubscriptionMutation, { data, loading, error }] = useInitialiseSubscriptionMutation({
 *   variables: {
 *      pricingId: // value for 'pricingId'
 *   },
 * });
 */
export function useInitialiseSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<InitialiseSubscriptionMutation, InitialiseSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitialiseSubscriptionMutation, InitialiseSubscriptionMutationVariables>(InitialiseSubscriptionDocument, options);
      }
export type InitialiseSubscriptionMutationHookResult = ReturnType<typeof useInitialiseSubscriptionMutation>;
export type InitialiseSubscriptionMutationResult = Apollo.MutationResult<InitialiseSubscriptionMutation>;
export type InitialiseSubscriptionMutationOptions = Apollo.BaseMutationOptions<InitialiseSubscriptionMutation, InitialiseSubscriptionMutationVariables>;
export const GetTeamSubscriptionDocument = gql`
    query GetTeamSubscription {
  getTeamSubscription {
    id
    status
    current_period_end
    product {
      id
      name
      description
      status
      pricing {
        id
        currency
        amountCents
        interval
      }
    }
    card {
      brand
      last4
    }
  }
}
    `;

/**
 * __useGetTeamSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetTeamSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamSubscriptionQuery, GetTeamSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamSubscriptionQuery, GetTeamSubscriptionQueryVariables>(GetTeamSubscriptionDocument, options);
      }
export function useGetTeamSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamSubscriptionQuery, GetTeamSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamSubscriptionQuery, GetTeamSubscriptionQueryVariables>(GetTeamSubscriptionDocument, options);
        }
export type GetTeamSubscriptionQueryHookResult = ReturnType<typeof useGetTeamSubscriptionQuery>;
export type GetTeamSubscriptionLazyQueryHookResult = ReturnType<typeof useGetTeamSubscriptionLazyQuery>;
export type GetTeamSubscriptionQueryResult = Apollo.QueryResult<GetTeamSubscriptionQuery, GetTeamSubscriptionQueryVariables>;
export const GetTeamPaymentPlansDocument = gql`
    query GetTeamPaymentPlans {
  getTeamPaymentPlans {
    id
    name
    description
    status
    pricing {
      id
      amountCents
      currency
      interval
    }
  }
}
    `;

/**
 * __useGetTeamPaymentPlansQuery__
 *
 * To run a query within a React component, call `useGetTeamPaymentPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamPaymentPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamPaymentPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamPaymentPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamPaymentPlansQuery, GetTeamPaymentPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamPaymentPlansQuery, GetTeamPaymentPlansQueryVariables>(GetTeamPaymentPlansDocument, options);
      }
export function useGetTeamPaymentPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamPaymentPlansQuery, GetTeamPaymentPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamPaymentPlansQuery, GetTeamPaymentPlansQueryVariables>(GetTeamPaymentPlansDocument, options);
        }
export type GetTeamPaymentPlansQueryHookResult = ReturnType<typeof useGetTeamPaymentPlansQuery>;
export type GetTeamPaymentPlansLazyQueryHookResult = ReturnType<typeof useGetTeamPaymentPlansLazyQuery>;
export type GetTeamPaymentPlansQueryResult = Apollo.QueryResult<GetTeamPaymentPlansQuery, GetTeamPaymentPlansQueryVariables>;
export const CancelTeamSubscriptionDocument = gql`
    mutation CancelTeamSubscription {
  cancelSubscription
}
    `;
export type CancelTeamSubscriptionMutationFn = Apollo.MutationFunction<CancelTeamSubscriptionMutation, CancelTeamSubscriptionMutationVariables>;

/**
 * __useCancelTeamSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelTeamSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTeamSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTeamSubscriptionMutation, { data, loading, error }] = useCancelTeamSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelTeamSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelTeamSubscriptionMutation, CancelTeamSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelTeamSubscriptionMutation, CancelTeamSubscriptionMutationVariables>(CancelTeamSubscriptionDocument, options);
      }
export type CancelTeamSubscriptionMutationHookResult = ReturnType<typeof useCancelTeamSubscriptionMutation>;
export type CancelTeamSubscriptionMutationResult = Apollo.MutationResult<CancelTeamSubscriptionMutation>;
export type CancelTeamSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelTeamSubscriptionMutation, CancelTeamSubscriptionMutationVariables>;
export const TakeMoneyDocument = gql`
    mutation TakeMoney($input: TakeMoneyInput!) {
  takeMoney(input: $input) {
    id
  }
}
    `;
export type TakeMoneyMutationFn = Apollo.MutationFunction<TakeMoneyMutation, TakeMoneyMutationVariables>;

/**
 * __useTakeMoneyMutation__
 *
 * To run a mutation, you first call `useTakeMoneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakeMoneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takeMoneyMutation, { data, loading, error }] = useTakeMoneyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTakeMoneyMutation(baseOptions?: Apollo.MutationHookOptions<TakeMoneyMutation, TakeMoneyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TakeMoneyMutation, TakeMoneyMutationVariables>(TakeMoneyDocument, options);
      }
export type TakeMoneyMutationHookResult = ReturnType<typeof useTakeMoneyMutation>;
export type TakeMoneyMutationResult = Apollo.MutationResult<TakeMoneyMutation>;
export type TakeMoneyMutationOptions = Apollo.BaseMutationOptions<TakeMoneyMutation, TakeMoneyMutationVariables>;
export const SpendMoneyDocument = gql`
    mutation SpendMoney($input: SpendMoneyInput!) {
  spendMoney(input: $input) {
    id
  }
}
    `;
export type SpendMoneyMutationFn = Apollo.MutationFunction<SpendMoneyMutation, SpendMoneyMutationVariables>;

/**
 * __useSpendMoneyMutation__
 *
 * To run a mutation, you first call `useSpendMoneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpendMoneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [spendMoneyMutation, { data, loading, error }] = useSpendMoneyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSpendMoneyMutation(baseOptions?: Apollo.MutationHookOptions<SpendMoneyMutation, SpendMoneyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SpendMoneyMutation, SpendMoneyMutationVariables>(SpendMoneyDocument, options);
      }
export type SpendMoneyMutationHookResult = ReturnType<typeof useSpendMoneyMutation>;
export type SpendMoneyMutationResult = Apollo.MutationResult<SpendMoneyMutation>;
export type SpendMoneyMutationOptions = Apollo.BaseMutationOptions<SpendMoneyMutation, SpendMoneyMutationVariables>;
export const GetBalanceTopupPlansDocument = gql`
    query GetBalanceTopupPlans {
  getBalanceTopupPlans {
    id
    name
    description
    status
    pricing {
      id
      currency
      amountCents
    }
  }
}
    `;

/**
 * __useGetBalanceTopupPlansQuery__
 *
 * To run a query within a React component, call `useGetBalanceTopupPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBalanceTopupPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBalanceTopupPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBalanceTopupPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetBalanceTopupPlansQuery, GetBalanceTopupPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBalanceTopupPlansQuery, GetBalanceTopupPlansQueryVariables>(GetBalanceTopupPlansDocument, options);
      }
export function useGetBalanceTopupPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBalanceTopupPlansQuery, GetBalanceTopupPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBalanceTopupPlansQuery, GetBalanceTopupPlansQueryVariables>(GetBalanceTopupPlansDocument, options);
        }
export type GetBalanceTopupPlansQueryHookResult = ReturnType<typeof useGetBalanceTopupPlansQuery>;
export type GetBalanceTopupPlansLazyQueryHookResult = ReturnType<typeof useGetBalanceTopupPlansLazyQuery>;
export type GetBalanceTopupPlansQueryResult = Apollo.QueryResult<GetBalanceTopupPlansQuery, GetBalanceTopupPlansQueryVariables>;
export const InitTopupPaymentDocument = gql`
    mutation InitTopupPayment($pricingId: String!) {
  initialiseTopupPayment(pricingID: $pricingId) {
    clientSecret
  }
}
    `;
export type InitTopupPaymentMutationFn = Apollo.MutationFunction<InitTopupPaymentMutation, InitTopupPaymentMutationVariables>;

/**
 * __useInitTopupPaymentMutation__
 *
 * To run a mutation, you first call `useInitTopupPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitTopupPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initTopupPaymentMutation, { data, loading, error }] = useInitTopupPaymentMutation({
 *   variables: {
 *      pricingId: // value for 'pricingId'
 *   },
 * });
 */
export function useInitTopupPaymentMutation(baseOptions?: Apollo.MutationHookOptions<InitTopupPaymentMutation, InitTopupPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitTopupPaymentMutation, InitTopupPaymentMutationVariables>(InitTopupPaymentDocument, options);
      }
export type InitTopupPaymentMutationHookResult = ReturnType<typeof useInitTopupPaymentMutation>;
export type InitTopupPaymentMutationResult = Apollo.MutationResult<InitTopupPaymentMutation>;
export type InitTopupPaymentMutationOptions = Apollo.BaseMutationOptions<InitTopupPaymentMutation, InitTopupPaymentMutationVariables>;
export const GetPlayersOnTeamDocument = gql`
    query GetPlayersOnTeam {
  getPlayersOnTeam {
    id
    name
    status
    teamProfile {
      id
      isTeamAdmin
      picture
      position
      status
    }
  }
}
    `;

/**
 * __useGetPlayersOnTeamQuery__
 *
 * To run a query within a React component, call `useGetPlayersOnTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayersOnTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayersOnTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlayersOnTeamQuery(baseOptions?: Apollo.QueryHookOptions<GetPlayersOnTeamQuery, GetPlayersOnTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlayersOnTeamQuery, GetPlayersOnTeamQueryVariables>(GetPlayersOnTeamDocument, options);
      }
export function useGetPlayersOnTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlayersOnTeamQuery, GetPlayersOnTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlayersOnTeamQuery, GetPlayersOnTeamQueryVariables>(GetPlayersOnTeamDocument, options);
        }
export type GetPlayersOnTeamQueryHookResult = ReturnType<typeof useGetPlayersOnTeamQuery>;
export type GetPlayersOnTeamLazyQueryHookResult = ReturnType<typeof useGetPlayersOnTeamLazyQuery>;
export type GetPlayersOnTeamQueryResult = Apollo.QueryResult<GetPlayersOnTeamQuery, GetPlayersOnTeamQueryVariables>;
export const UpdatePlayersTeamProfileDocument = gql`
    mutation UpdatePlayersTeamProfile($updatePlayersTeamProfileInput: UpdatePlayerTeamInput!) {
  updatePlayersTeamProfile(data: $updatePlayersTeamProfileInput)
}
    `;
export type UpdatePlayersTeamProfileMutationFn = Apollo.MutationFunction<UpdatePlayersTeamProfileMutation, UpdatePlayersTeamProfileMutationVariables>;

/**
 * __useUpdatePlayersTeamProfileMutation__
 *
 * To run a mutation, you first call `useUpdatePlayersTeamProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayersTeamProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayersTeamProfileMutation, { data, loading, error }] = useUpdatePlayersTeamProfileMutation({
 *   variables: {
 *      updatePlayersTeamProfileInput: // value for 'updatePlayersTeamProfileInput'
 *   },
 * });
 */
export function useUpdatePlayersTeamProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlayersTeamProfileMutation, UpdatePlayersTeamProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlayersTeamProfileMutation, UpdatePlayersTeamProfileMutationVariables>(UpdatePlayersTeamProfileDocument, options);
      }
export type UpdatePlayersTeamProfileMutationHookResult = ReturnType<typeof useUpdatePlayersTeamProfileMutation>;
export type UpdatePlayersTeamProfileMutationResult = Apollo.MutationResult<UpdatePlayersTeamProfileMutation>;
export type UpdatePlayersTeamProfileMutationOptions = Apollo.BaseMutationOptions<UpdatePlayersTeamProfileMutation, UpdatePlayersTeamProfileMutationVariables>;
export const RemovePlayerFromTeamDocument = gql`
    mutation RemovePlayerFromTeam($teamProfileID: String!) {
  removePlayerFromTeam(teamProfileID: $teamProfileID)
}
    `;
export type RemovePlayerFromTeamMutationFn = Apollo.MutationFunction<RemovePlayerFromTeamMutation, RemovePlayerFromTeamMutationVariables>;

/**
 * __useRemovePlayerFromTeamMutation__
 *
 * To run a mutation, you first call `useRemovePlayerFromTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePlayerFromTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePlayerFromTeamMutation, { data, loading, error }] = useRemovePlayerFromTeamMutation({
 *   variables: {
 *      teamProfileID: // value for 'teamProfileID'
 *   },
 * });
 */
export function useRemovePlayerFromTeamMutation(baseOptions?: Apollo.MutationHookOptions<RemovePlayerFromTeamMutation, RemovePlayerFromTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePlayerFromTeamMutation, RemovePlayerFromTeamMutationVariables>(RemovePlayerFromTeamDocument, options);
      }
export type RemovePlayerFromTeamMutationHookResult = ReturnType<typeof useRemovePlayerFromTeamMutation>;
export type RemovePlayerFromTeamMutationResult = Apollo.MutationResult<RemovePlayerFromTeamMutation>;
export type RemovePlayerFromTeamMutationOptions = Apollo.BaseMutationOptions<RemovePlayerFromTeamMutation, RemovePlayerFromTeamMutationVariables>;
export const GetMyPendingJoinRequestsDocument = gql`
    query GetMyPendingJoinRequests {
  getMyPendingJoinRequests {
    id
    team {
      name
    }
    status
    createdAt
  }
}
    `;

/**
 * __useGetMyPendingJoinRequestsQuery__
 *
 * To run a query within a React component, call `useGetMyPendingJoinRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPendingJoinRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPendingJoinRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPendingJoinRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyPendingJoinRequestsQuery, GetMyPendingJoinRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyPendingJoinRequestsQuery, GetMyPendingJoinRequestsQueryVariables>(GetMyPendingJoinRequestsDocument, options);
      }
export function useGetMyPendingJoinRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyPendingJoinRequestsQuery, GetMyPendingJoinRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyPendingJoinRequestsQuery, GetMyPendingJoinRequestsQueryVariables>(GetMyPendingJoinRequestsDocument, options);
        }
export type GetMyPendingJoinRequestsQueryHookResult = ReturnType<typeof useGetMyPendingJoinRequestsQuery>;
export type GetMyPendingJoinRequestsLazyQueryHookResult = ReturnType<typeof useGetMyPendingJoinRequestsLazyQuery>;
export type GetMyPendingJoinRequestsQueryResult = Apollo.QueryResult<GetMyPendingJoinRequestsQuery, GetMyPendingJoinRequestsQueryVariables>;
export const GetMyProfileDocument = gql`
    query GetMyProfile {
  getMyProfile {
    authProvider
    emailConfirmed
    player {
      id
      name
      email
    }
    teamProfile {
      position
      picture
      isTeamAdmin
      hasJoinedTeamChat
      team {
        id
        name
        image
        description
        whatsappJoinLink
        owner {
          id
          name
        }
      }
    }
    teams {
      id
      name
    }
    totalGames
    totalGamesWon
    totalMvpVotes
    totalGoals
    averageGoalsPerMatch
  }
}
    `;

/**
 * __useGetMyProfileQuery__
 *
 * To run a query within a React component, call `useGetMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options);
      }
export function useGetMyProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options);
        }
export type GetMyProfileQueryHookResult = ReturnType<typeof useGetMyProfileQuery>;
export type GetMyProfileLazyQueryHookResult = ReturnType<typeof useGetMyProfileLazyQuery>;
export type GetMyProfileQueryResult = Apollo.QueryResult<GetMyProfileQuery, GetMyProfileQueryVariables>;
export const GetProfileDocument = gql`
    query GetProfile($playerID: String!) {
  getPlayersProfile(playerID: $playerID) {
    player {
      id
      name
    }
    teamProfile {
      position
      picture
      isTeamAdmin
      hasJoinedTeamChat
      team {
        id
        name
        image
        owner {
          id
          name
        }
      }
    }
    teams {
      id
      name
      whatsappJoinLink
    }
    totalGames
    totalGamesWon
    totalMvpVotes
    totalGoals
    averageGoalsPerMatch
    recentReviews {
      id
      highlight
      win
      goals
      createdAt
    }
    recentTransactions {
      id
      name
      createdAt
      amount
      type
    }
    totalMvpWins
  }
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      playerID: // value for 'playerID'
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const UpdatePlayerDocument = gql`
    mutation UpdatePlayer($inputs: UpdatePlayerInputs!) {
  updatePlayer(inputs: $inputs) {
    id
    name
    email
  }
}
    `;
export type UpdatePlayerMutationFn = Apollo.MutationFunction<UpdatePlayerMutation, UpdatePlayerMutationVariables>;

/**
 * __useUpdatePlayerMutation__
 *
 * To run a mutation, you first call `useUpdatePlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayerMutation, { data, loading, error }] = useUpdatePlayerMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdatePlayerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlayerMutation, UpdatePlayerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlayerMutation, UpdatePlayerMutationVariables>(UpdatePlayerDocument, options);
      }
export type UpdatePlayerMutationHookResult = ReturnType<typeof useUpdatePlayerMutation>;
export type UpdatePlayerMutationResult = Apollo.MutationResult<UpdatePlayerMutation>;
export type UpdatePlayerMutationOptions = Apollo.BaseMutationOptions<UpdatePlayerMutation, UpdatePlayerMutationVariables>;
export const GetNotificationsDocument = gql`
    query GetNotifications($inputs: GetNotificationInputs!) {
  getNotifications(inputs: $inputs) {
    id
    player {
      id
      name
    }
    priority
    isRead
    message
    redirect
    createdAt
    deletedAt
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const MarkNotificationsReadDocument = gql`
    mutation MarkNotificationsRead {
  markAllRead
}
    `;
export type MarkNotificationsReadMutationFn = Apollo.MutationFunction<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>;

/**
 * __useMarkNotificationsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsReadMutation, { data, loading, error }] = useMarkNotificationsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkNotificationsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>(MarkNotificationsReadDocument, options);
      }
export type MarkNotificationsReadMutationHookResult = ReturnType<typeof useMarkNotificationsReadMutation>;
export type MarkNotificationsReadMutationResult = Apollo.MutationResult<MarkNotificationsReadMutation>;
export type MarkNotificationsReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables>;
export const CashOutRequestDocument = gql`
    mutation CashOutRequest($input: CashOutRequestInput!) {
  cashOutRequest(input: $input)
}
    `;
export type CashOutRequestMutationFn = Apollo.MutationFunction<CashOutRequestMutation, CashOutRequestMutationVariables>;

/**
 * __useCashOutRequestMutation__
 *
 * To run a mutation, you first call `useCashOutRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCashOutRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cashOutRequestMutation, { data, loading, error }] = useCashOutRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCashOutRequestMutation(baseOptions?: Apollo.MutationHookOptions<CashOutRequestMutation, CashOutRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CashOutRequestMutation, CashOutRequestMutationVariables>(CashOutRequestDocument, options);
      }
export type CashOutRequestMutationHookResult = ReturnType<typeof useCashOutRequestMutation>;
export type CashOutRequestMutationResult = Apollo.MutationResult<CashOutRequestMutation>;
export type CashOutRequestMutationOptions = Apollo.BaseMutationOptions<CashOutRequestMutation, CashOutRequestMutationVariables>;
export const ToggleActiveTeamDocument = gql`
    mutation ToggleActiveTeam($newTeamID: String!) {
  toggleActiveTeam(newTeamID: $newTeamID)
}
    `;
export type ToggleActiveTeamMutationFn = Apollo.MutationFunction<ToggleActiveTeamMutation, ToggleActiveTeamMutationVariables>;

/**
 * __useToggleActiveTeamMutation__
 *
 * To run a mutation, you first call `useToggleActiveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleActiveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleActiveTeamMutation, { data, loading, error }] = useToggleActiveTeamMutation({
 *   variables: {
 *      newTeamID: // value for 'newTeamID'
 *   },
 * });
 */
export function useToggleActiveTeamMutation(baseOptions?: Apollo.MutationHookOptions<ToggleActiveTeamMutation, ToggleActiveTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleActiveTeamMutation, ToggleActiveTeamMutationVariables>(ToggleActiveTeamDocument, options);
      }
export type ToggleActiveTeamMutationHookResult = ReturnType<typeof useToggleActiveTeamMutation>;
export type ToggleActiveTeamMutationResult = Apollo.MutationResult<ToggleActiveTeamMutation>;
export type ToggleActiveTeamMutationOptions = Apollo.BaseMutationOptions<ToggleActiveTeamMutation, ToggleActiveTeamMutationVariables>;
export const GetActiveTeamDocument = gql`
    query GetActiveTeam {
  getPlayersActiveTeamProfile {
    team {
      id
      name
    }
  }
}
    `;

/**
 * __useGetActiveTeamQuery__
 *
 * To run a query within a React component, call `useGetActiveTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveTeamQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveTeamQuery, GetActiveTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveTeamQuery, GetActiveTeamQueryVariables>(GetActiveTeamDocument, options);
      }
export function useGetActiveTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveTeamQuery, GetActiveTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveTeamQuery, GetActiveTeamQueryVariables>(GetActiveTeamDocument, options);
        }
export type GetActiveTeamQueryHookResult = ReturnType<typeof useGetActiveTeamQuery>;
export type GetActiveTeamLazyQueryHookResult = ReturnType<typeof useGetActiveTeamLazyQuery>;
export type GetActiveTeamQueryResult = Apollo.QueryResult<GetActiveTeamQuery, GetActiveTeamQueryVariables>;
export const GetPlayersTeamsDocument = gql`
    query GetPlayersTeams {
  getPlayersTeams {
    id
    isTeamAdmin
    picture
    position
    status
    team {
      id
      name
      active
      description
      whatsappJoinLink
      joinCode
      owner {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetPlayersTeamsQuery__
 *
 * To run a query within a React component, call `useGetPlayersTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayersTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayersTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlayersTeamsQuery(baseOptions?: Apollo.QueryHookOptions<GetPlayersTeamsQuery, GetPlayersTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlayersTeamsQuery, GetPlayersTeamsQueryVariables>(GetPlayersTeamsDocument, options);
      }
export function useGetPlayersTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlayersTeamsQuery, GetPlayersTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlayersTeamsQuery, GetPlayersTeamsQueryVariables>(GetPlayersTeamsDocument, options);
        }
export type GetPlayersTeamsQueryHookResult = ReturnType<typeof useGetPlayersTeamsQuery>;
export type GetPlayersTeamsLazyQueryHookResult = ReturnType<typeof useGetPlayersTeamsLazyQuery>;
export type GetPlayersTeamsQueryResult = Apollo.QueryResult<GetPlayersTeamsQuery, GetPlayersTeamsQueryVariables>;
export const CreateTeamDocument = gql`
    mutation CreateTeam($input: CreateTeamInput!) {
  createTeam(input: $input) {
    id
  }
}
    `;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, options);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($input: UpdateTeamInput!) {
  updateTeam(input: $input) {
    id
  }
}
    `;
export type UpdateTeamMutationFn = Apollo.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, options);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const GetTeamDocument = gql`
    query GetTeam($teamId: String!) {
  getTeam(teamID: $teamId) {
    id
    active
    name
    description
    whatsappJoinLink
    joinCode
    venues {
      id
      name
      active
      logo
      phone
      website
      address
    }
    image
  }
}
    `;

/**
 * __useGetTeamQuery__
 *
 * To run a query within a React component, call `useGetTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetTeamQuery(baseOptions: Apollo.QueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
      }
export function useGetTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamQuery, GetTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamQuery, GetTeamQueryVariables>(GetTeamDocument, options);
        }
export type GetTeamQueryHookResult = ReturnType<typeof useGetTeamQuery>;
export type GetTeamLazyQueryHookResult = ReturnType<typeof useGetTeamLazyQuery>;
export type GetTeamQueryResult = Apollo.QueryResult<GetTeamQuery, GetTeamQueryVariables>;
export const GetTeamConfigDocument = gql`
    query GetTeamConfig {
  getTeamConfig {
    id
    configValueType
    configItem
    configValue
    deletedByPlayer {
      id
      name
    }
    deletedAt
    createdAt
    createdByPlayer {
      id
      name
    }
    updatedAt
    updatedByPlayer {
      id
      name
    }
  }
}
    `;

/**
 * __useGetTeamConfigQuery__
 *
 * To run a query within a React component, call `useGetTeamConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamConfigQuery, GetTeamConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamConfigQuery, GetTeamConfigQueryVariables>(GetTeamConfigDocument, options);
      }
export function useGetTeamConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamConfigQuery, GetTeamConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamConfigQuery, GetTeamConfigQueryVariables>(GetTeamConfigDocument, options);
        }
export type GetTeamConfigQueryHookResult = ReturnType<typeof useGetTeamConfigQuery>;
export type GetTeamConfigLazyQueryHookResult = ReturnType<typeof useGetTeamConfigLazyQuery>;
export type GetTeamConfigQueryResult = Apollo.QueryResult<GetTeamConfigQuery, GetTeamConfigQueryVariables>;
export const GetTeamRulesDocument = gql`
    query GetTeamRules {
  getTeamRules {
    id
    team {
      id
      name
    }
    ruleTitle
    ruleDetails
    createdAt
    createdByPlayer {
      id
      name
    }
    updatedAt
    updatedByPlayer {
      id
      name
    }
  }
}
    `;

/**
 * __useGetTeamRulesQuery__
 *
 * To run a query within a React component, call `useGetTeamRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamRulesQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamRulesQuery, GetTeamRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamRulesQuery, GetTeamRulesQueryVariables>(GetTeamRulesDocument, options);
      }
export function useGetTeamRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamRulesQuery, GetTeamRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamRulesQuery, GetTeamRulesQueryVariables>(GetTeamRulesDocument, options);
        }
export type GetTeamRulesQueryHookResult = ReturnType<typeof useGetTeamRulesQuery>;
export type GetTeamRulesLazyQueryHookResult = ReturnType<typeof useGetTeamRulesLazyQuery>;
export type GetTeamRulesQueryResult = Apollo.QueryResult<GetTeamRulesQuery, GetTeamRulesQueryVariables>;
export const CreateTeamRuleDocument = gql`
    mutation CreateTeamRule($input: CreateTeamRuleInput!) {
  createTeamRule(input: $input) {
    id
  }
}
    `;
export type CreateTeamRuleMutationFn = Apollo.MutationFunction<CreateTeamRuleMutation, CreateTeamRuleMutationVariables>;

/**
 * __useCreateTeamRuleMutation__
 *
 * To run a mutation, you first call `useCreateTeamRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamRuleMutation, { data, loading, error }] = useCreateTeamRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamRuleMutation, CreateTeamRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamRuleMutation, CreateTeamRuleMutationVariables>(CreateTeamRuleDocument, options);
      }
export type CreateTeamRuleMutationHookResult = ReturnType<typeof useCreateTeamRuleMutation>;
export type CreateTeamRuleMutationResult = Apollo.MutationResult<CreateTeamRuleMutation>;
export type CreateTeamRuleMutationOptions = Apollo.BaseMutationOptions<CreateTeamRuleMutation, CreateTeamRuleMutationVariables>;
export const UpdateTeamRuleDocument = gql`
    mutation UpdateTeamRule($input: EditTeamRuleInput!) {
  updateTeamRule(input: $input) {
    id
  }
}
    `;
export type UpdateTeamRuleMutationFn = Apollo.MutationFunction<UpdateTeamRuleMutation, UpdateTeamRuleMutationVariables>;

/**
 * __useUpdateTeamRuleMutation__
 *
 * To run a mutation, you first call `useUpdateTeamRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamRuleMutation, { data, loading, error }] = useUpdateTeamRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamRuleMutation, UpdateTeamRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamRuleMutation, UpdateTeamRuleMutationVariables>(UpdateTeamRuleDocument, options);
      }
export type UpdateTeamRuleMutationHookResult = ReturnType<typeof useUpdateTeamRuleMutation>;
export type UpdateTeamRuleMutationResult = Apollo.MutationResult<UpdateTeamRuleMutation>;
export type UpdateTeamRuleMutationOptions = Apollo.BaseMutationOptions<UpdateTeamRuleMutation, UpdateTeamRuleMutationVariables>;
export const DeleteTeamRuleDocument = gql`
    mutation DeleteTeamRule($ruleId: String!) {
  deleteTeamRule(ruleID: $ruleId)
}
    `;
export type DeleteTeamRuleMutationFn = Apollo.MutationFunction<DeleteTeamRuleMutation, DeleteTeamRuleMutationVariables>;

/**
 * __useDeleteTeamRuleMutation__
 *
 * To run a mutation, you first call `useDeleteTeamRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamRuleMutation, { data, loading, error }] = useDeleteTeamRuleMutation({
 *   variables: {
 *      ruleId: // value for 'ruleId'
 *   },
 * });
 */
export function useDeleteTeamRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeamRuleMutation, DeleteTeamRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeamRuleMutation, DeleteTeamRuleMutationVariables>(DeleteTeamRuleDocument, options);
      }
export type DeleteTeamRuleMutationHookResult = ReturnType<typeof useDeleteTeamRuleMutation>;
export type DeleteTeamRuleMutationResult = Apollo.MutationResult<DeleteTeamRuleMutation>;
export type DeleteTeamRuleMutationOptions = Apollo.BaseMutationOptions<DeleteTeamRuleMutation, DeleteTeamRuleMutationVariables>;
export const InvitePlayerToJoinTeamDocument = gql`
    mutation InvitePlayerToJoinTeam($input: InvitePlayerInputs!) {
  invitePlayerToJoinTeam(input: $input)
}
    `;
export type InvitePlayerToJoinTeamMutationFn = Apollo.MutationFunction<InvitePlayerToJoinTeamMutation, InvitePlayerToJoinTeamMutationVariables>;

/**
 * __useInvitePlayerToJoinTeamMutation__
 *
 * To run a mutation, you first call `useInvitePlayerToJoinTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvitePlayerToJoinTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invitePlayerToJoinTeamMutation, { data, loading, error }] = useInvitePlayerToJoinTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvitePlayerToJoinTeamMutation(baseOptions?: Apollo.MutationHookOptions<InvitePlayerToJoinTeamMutation, InvitePlayerToJoinTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvitePlayerToJoinTeamMutation, InvitePlayerToJoinTeamMutationVariables>(InvitePlayerToJoinTeamDocument, options);
      }
export type InvitePlayerToJoinTeamMutationHookResult = ReturnType<typeof useInvitePlayerToJoinTeamMutation>;
export type InvitePlayerToJoinTeamMutationResult = Apollo.MutationResult<InvitePlayerToJoinTeamMutation>;
export type InvitePlayerToJoinTeamMutationOptions = Apollo.BaseMutationOptions<InvitePlayerToJoinTeamMutation, InvitePlayerToJoinTeamMutationVariables>;
export const GetMyBalanceDocument = gql`
    query GetMyBalance {
  getPlayerBalance
}
    `;

/**
 * __useGetMyBalanceQuery__
 *
 * To run a query within a React component, call `useGetMyBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyBalanceQuery(baseOptions?: Apollo.QueryHookOptions<GetMyBalanceQuery, GetMyBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyBalanceQuery, GetMyBalanceQueryVariables>(GetMyBalanceDocument, options);
      }
export function useGetMyBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyBalanceQuery, GetMyBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyBalanceQuery, GetMyBalanceQueryVariables>(GetMyBalanceDocument, options);
        }
export type GetMyBalanceQueryHookResult = ReturnType<typeof useGetMyBalanceQuery>;
export type GetMyBalanceLazyQueryHookResult = ReturnType<typeof useGetMyBalanceLazyQuery>;
export type GetMyBalanceQueryResult = Apollo.QueryResult<GetMyBalanceQuery, GetMyBalanceQueryVariables>;
export const GetVenuesDocument = gql`
    query GetVenues {
  getVenues {
    id
    name
    active
    address
    postcode
    website
    logo
    phone
  }
}
    `;

/**
 * __useGetVenuesQuery__
 *
 * To run a query within a React component, call `useGetVenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVenuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVenuesQuery(baseOptions?: Apollo.QueryHookOptions<GetVenuesQuery, GetVenuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVenuesQuery, GetVenuesQueryVariables>(GetVenuesDocument, options);
      }
export function useGetVenuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVenuesQuery, GetVenuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVenuesQuery, GetVenuesQueryVariables>(GetVenuesDocument, options);
        }
export type GetVenuesQueryHookResult = ReturnType<typeof useGetVenuesQuery>;
export type GetVenuesLazyQueryHookResult = ReturnType<typeof useGetVenuesLazyQuery>;
export type GetVenuesQueryResult = Apollo.QueryResult<GetVenuesQuery, GetVenuesQueryVariables>;
export const CreateVenueDocument = gql`
    mutation CreateVenue($data: CreateVenueArgs!) {
  createVenue(data: $data) {
    id
  }
}
    `;
export type CreateVenueMutationFn = Apollo.MutationFunction<CreateVenueMutation, CreateVenueMutationVariables>;

/**
 * __useCreateVenueMutation__
 *
 * To run a mutation, you first call `useCreateVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVenueMutation, { data, loading, error }] = useCreateVenueMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateVenueMutation(baseOptions?: Apollo.MutationHookOptions<CreateVenueMutation, CreateVenueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVenueMutation, CreateVenueMutationVariables>(CreateVenueDocument, options);
      }
export type CreateVenueMutationHookResult = ReturnType<typeof useCreateVenueMutation>;
export type CreateVenueMutationResult = Apollo.MutationResult<CreateVenueMutation>;
export type CreateVenueMutationOptions = Apollo.BaseMutationOptions<CreateVenueMutation, CreateVenueMutationVariables>;
export const UpdateVenueDocument = gql`
    mutation UpdateVenue($data: UpdateVenueArgs!) {
  updateVenue(data: $data) {
    id
  }
}
    `;
export type UpdateVenueMutationFn = Apollo.MutationFunction<UpdateVenueMutation, UpdateVenueMutationVariables>;

/**
 * __useUpdateVenueMutation__
 *
 * To run a mutation, you first call `useUpdateVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVenueMutation, { data, loading, error }] = useUpdateVenueMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVenueMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVenueMutation, UpdateVenueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVenueMutation, UpdateVenueMutationVariables>(UpdateVenueDocument, options);
      }
export type UpdateVenueMutationHookResult = ReturnType<typeof useUpdateVenueMutation>;
export type UpdateVenueMutationResult = Apollo.MutationResult<UpdateVenueMutation>;
export type UpdateVenueMutationOptions = Apollo.BaseMutationOptions<UpdateVenueMutation, UpdateVenueMutationVariables>;
export const DeleteVenueDocument = gql`
    mutation DeleteVenue($deleteVenueId: String!) {
  deleteVenue(venueid: $deleteVenueId)
}
    `;
export type DeleteVenueMutationFn = Apollo.MutationFunction<DeleteVenueMutation, DeleteVenueMutationVariables>;

/**
 * __useDeleteVenueMutation__
 *
 * To run a mutation, you first call `useDeleteVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVenueMutation, { data, loading, error }] = useDeleteVenueMutation({
 *   variables: {
 *      deleteVenueId: // value for 'deleteVenueId'
 *   },
 * });
 */
export function useDeleteVenueMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVenueMutation, DeleteVenueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVenueMutation, DeleteVenueMutationVariables>(DeleteVenueDocument, options);
      }
export type DeleteVenueMutationHookResult = ReturnType<typeof useDeleteVenueMutation>;
export type DeleteVenueMutationResult = Apollo.MutationResult<DeleteVenueMutation>;
export type DeleteVenueMutationOptions = Apollo.BaseMutationOptions<DeleteVenueMutation, DeleteVenueMutationVariables>;
export const LookupPlacePredictionsDocument = gql`
    query LookupPlacePredictions($address: String!) {
  lookupPlacePredictions(address: $address) {
    description
    distance_meters
    place_id
    terms {
      offset
      value
    }
    types
    matched_substrings {
      length
      offset
    }
    structured_formatting {
      main_text
      main_text_matched_substrings {
        offset
        length
      }
      secondary_text
      secondary_text_matched_substrings {
        length
        offset
      }
    }
  }
}
    `;

/**
 * __useLookupPlacePredictionsQuery__
 *
 * To run a query within a React component, call `useLookupPlacePredictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupPlacePredictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupPlacePredictionsQuery({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useLookupPlacePredictionsQuery(baseOptions: Apollo.QueryHookOptions<LookupPlacePredictionsQuery, LookupPlacePredictionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LookupPlacePredictionsQuery, LookupPlacePredictionsQueryVariables>(LookupPlacePredictionsDocument, options);
      }
export function useLookupPlacePredictionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LookupPlacePredictionsQuery, LookupPlacePredictionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LookupPlacePredictionsQuery, LookupPlacePredictionsQueryVariables>(LookupPlacePredictionsDocument, options);
        }
export type LookupPlacePredictionsQueryHookResult = ReturnType<typeof useLookupPlacePredictionsQuery>;
export type LookupPlacePredictionsLazyQueryHookResult = ReturnType<typeof useLookupPlacePredictionsLazyQuery>;
export type LookupPlacePredictionsQueryResult = Apollo.QueryResult<LookupPlacePredictionsQuery, LookupPlacePredictionsQueryVariables>;
export const LookupPlaceDetailsDocument = gql`
    query LookupPlaceDetails($placeid: String!) {
  lookupPlace(placeid: $placeid) {
    name
    formatted_address
    formatted_phone_number
    website
    rating
    types
    postcode
    lat
    lng
  }
}
    `;

/**
 * __useLookupPlaceDetailsQuery__
 *
 * To run a query within a React component, call `useLookupPlaceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupPlaceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupPlaceDetailsQuery({
 *   variables: {
 *      placeid: // value for 'placeid'
 *   },
 * });
 */
export function useLookupPlaceDetailsQuery(baseOptions: Apollo.QueryHookOptions<LookupPlaceDetailsQuery, LookupPlaceDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LookupPlaceDetailsQuery, LookupPlaceDetailsQueryVariables>(LookupPlaceDetailsDocument, options);
      }
export function useLookupPlaceDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LookupPlaceDetailsQuery, LookupPlaceDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LookupPlaceDetailsQuery, LookupPlaceDetailsQueryVariables>(LookupPlaceDetailsDocument, options);
        }
export type LookupPlaceDetailsQueryHookResult = ReturnType<typeof useLookupPlaceDetailsQuery>;
export type LookupPlaceDetailsLazyQueryHookResult = ReturnType<typeof useLookupPlaceDetailsLazyQuery>;
export type LookupPlaceDetailsQueryResult = Apollo.QueryResult<LookupPlaceDetailsQuery, LookupPlaceDetailsQueryVariables>;
export const GoogleRegisterDocument = gql`
    mutation GoogleRegister($code: String!) {
  googleRegister(code: $code) {
    accessToken
  }
}
    `;
export type GoogleRegisterMutationFn = Apollo.MutationFunction<GoogleRegisterMutation, GoogleRegisterMutationVariables>;

/**
 * __useGoogleRegisterMutation__
 *
 * To run a mutation, you first call `useGoogleRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleRegisterMutation, { data, loading, error }] = useGoogleRegisterMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGoogleRegisterMutation(baseOptions?: Apollo.MutationHookOptions<GoogleRegisterMutation, GoogleRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleRegisterMutation, GoogleRegisterMutationVariables>(GoogleRegisterDocument, options);
      }
export type GoogleRegisterMutationHookResult = ReturnType<typeof useGoogleRegisterMutation>;
export type GoogleRegisterMutationResult = Apollo.MutationResult<GoogleRegisterMutation>;
export type GoogleRegisterMutationOptions = Apollo.BaseMutationOptions<GoogleRegisterMutation, GoogleRegisterMutationVariables>;
export const EmailRegisterDocument = gql`
    mutation EmailRegister($input: EmailRegisterInput!) {
  emailRegister(input: $input) {
    accessToken
  }
}
    `;
export type EmailRegisterMutationFn = Apollo.MutationFunction<EmailRegisterMutation, EmailRegisterMutationVariables>;

/**
 * __useEmailRegisterMutation__
 *
 * To run a mutation, you first call `useEmailRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailRegisterMutation, { data, loading, error }] = useEmailRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailRegisterMutation(baseOptions?: Apollo.MutationHookOptions<EmailRegisterMutation, EmailRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailRegisterMutation, EmailRegisterMutationVariables>(EmailRegisterDocument, options);
      }
export type EmailRegisterMutationHookResult = ReturnType<typeof useEmailRegisterMutation>;
export type EmailRegisterMutationResult = Apollo.MutationResult<EmailRegisterMutation>;
export type EmailRegisterMutationOptions = Apollo.BaseMutationOptions<EmailRegisterMutation, EmailRegisterMutationVariables>;