import { Fade } from "@mui/material";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

// Custom global types
interface GlobalState {
  accessToken: string;
}

const GlobalContext = createContext<
  | {
      globalState: GlobalState;
      setGlobalState: Dispatch<SetStateAction<GlobalState>>;
    }
  | undefined
>(undefined);

interface GlobalContextProviderProps {
  children: React.ReactNode;
}

function GlobalContextProvider(props: GlobalContextProviderProps) {
  const [globalState, setGlobalState] = useState<GlobalState>({
    accessToken: "",
  });

  const value = {
    globalState,
    setGlobalState,
  };

  return (
    <GlobalContext.Provider value={value}>
      <Fade in>
        <div>
          <>{props.children}</>
        </div>
      </Fade>
    </GlobalContext.Provider>
  );
}

function useGlobal() {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobal must be used within a GlobalContext");
  }
  return context;
}

export { GlobalContextProvider, useGlobal };
