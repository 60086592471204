import { Alert } from "@mui/material";
import { LoadingBus } from "components/common";
import { Profile, TeamProfile, useProfile } from "hooks";

export interface WithProfileProps {
  profile: Profile;
  activeTeamProfile: TeamProfile;
}

function WithProfile(WrappedComponent: React.ComponentType<WithProfileProps>) {
  const { profile, activeTeamProfile, profileLoading, profileError } =
    useProfile();

  const errorMessage = profileError?.message ?? "Error loading profile";

  if (profileLoading) {
    return <LoadingBus />;
  }

  if (profileError) {
    return <Alert severity="error">{errorMessage}</Alert>;
  }

  if (!profile) {
    throw new Error("Unable to load profile");
  }

  return (
    <WrappedComponent profile={profile} activeTeamProfile={activeTeamProfile} />
  );
}

export default WithProfile;
