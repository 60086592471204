import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import AuthWrapper from "AuthWrapper";
import { useGlobal } from "contexts/GlobalContext";
import AppRoutes from "./AppRoutes";
import "@stripe/stripe-js";
import { GoogleOAuthProvider } from "@react-oauth/google";

export const cache = new InMemoryCache();
export let apolloClient: ApolloClient<any>;

function App() {
  const {
    globalState: { accessToken },
  } = useGlobal();

  const graphqlEndpoint = import.meta.env.PROD
    ? `${import.meta.env.VITE_BACKEND_ENDPOINT}/graphql`
    : "http://localhost:4000/graphql";

  // add access token to the headers
  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    }));

    return forward(operation);
  });

  const additiveLink = from([
    authMiddleware,
    createUploadLink({
      uri: graphqlEndpoint ?? "",
      credentials: "include",
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }),
  ]);

  apolloClient = new ApolloClient({
    link: additiveLink,
    cache,
  });

  return (
    <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
      <ApolloProvider client={apolloClient}>
        <AuthWrapper>
          <AppRoutes />
        </AuthWrapper>
      </ApolloProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
