import { LoadingBus } from "components/common";
import { useGlobal } from "contexts/GlobalContext";
import { useProfile } from "hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AwaitingConfirmation from "screens/awaitingConfirmation/AwaitingConfirmation";
import ConfirmEmailCallback from "screens/awaitingConfirmation/ConfirmEmailCallback";
import Login from "screens/login/Login";
import NoActiveTeam from "screens/noActiveTeam/NoActiveTeam";

interface AuthWrapperProps {
  children: JSX.Element;
}

function AuthWrapper(props: AuthWrapperProps) {
  const [authLoading, setAuthLoading] = useState(true);
  const navigate = useNavigate();
  const {
    globalState: { accessToken },
    setGlobalState,
  } = useGlobal();

  useEffect(() => {
    import.meta.env.DEV &&
      accessToken &&
      console.log("accessToken: %o", accessToken);
  }, [accessToken]);

  const location = useLocation();
  const pathname = location.pathname;

  const refreshEndpoint = import.meta.env.PROD
    ? `${import.meta.env.VITE_BACKEND_ENDPOINT}/refresh_token`
    : "http://localhost:4000/refresh_token";

  const publicRoutes = ["/login", "/register", "/service"];
  // Use refresh token to get a new access token if one doesnt exist
  useEffect(() => {
    if (
      publicRoutes.includes(pathname) ||
      pathname.startsWith("/auth/email/confirm/")
    ) {
      // Skip refresh token if we are on a public page
      setAuthLoading(false);
      return;
    }
    fetch(refreshEndpoint, {
      method: "POST",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        if (!data.ok) {
          navigate("/login");
        } else {
          setGlobalState({
            accessToken: data.accessToken,
          });
        }
        setAuthLoading(false);
      })
      .catch((error) => {
        console.error("Error refreshing token:" + error);
        setAuthLoading(false);
      });
  }, [pathname]);

  const { profile, profileLoading } = useProfile(
    authLoading || publicRoutes.includes(pathname)
  );

  const isLoading = authLoading || profileLoading;

  // If confirming email
  if (pathname.startsWith("/auth/email/confirm/")) {
    return <ConfirmEmailCallback />;
  }

  // If accessing a public route, no auth required
  if (
    publicRoutes.includes(pathname) //||
    // pathname.startsWith("/auth/email/confirm/")
  ) {
    return <>{props.children}</>;
  }

  // If email is not confirmed, redirect to awaiting email confirmation screen
  if (accessToken && profile && !profile.emailConfirmed) {
    return <AwaitingConfirmation email={profile?.player?.email ?? ""} />;
  }

  // If not logged in, redirect to login
  if (!isLoading && !accessToken) {
    return <Login />;
  }

  // Would be nice to only display LoadingBus after 1s to avoid the flash
  if (isLoading) {
    return <LoadingBus />;
  }

  if (profile && !profile.teamProfile && pathname !== "/teams/create") {
    return <NoActiveTeam profile={profile} />;
  }

  return <>{props.children}</>;
}

export default AuthWrapper;
