import {
  Box,
  Button,
  Container,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingTile } from "components/common";
import { useConfirmEmailMutation } from "graphql/generated";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as WelcomeImage } from "assets/images/undraw/welcome_cats.svg";

// When registering, this component will confirm token from email and validate user in database

function ConfirmEmailCallback() {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [confirmEmail] = useConfirmEmailMutation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = window.location.href.split("/").reverse()[0];
    confirmEmail({
      variables: { token },
    }).then((response) => {
      if (!response.data?.confirmEmail) {
        console.error("Error confirming email");
      }
      setConfirmed(true);
    });
  }, []);

  return (
    <Box>
      {!confirmed ? (
        <Paper sx={{ backgroundColor: "#121212", minHeight: "100vh" }}>
          <LinearProgress color="inherit" />
          <Container maxWidth="xs">
            <LoadingTile message="Confirming email..." align="center" />
          </Container>
        </Paper>
      ) : (
        <Paper sx={{ backgroundColor: "#121212", minHeight: "100vh" }}>
          <Container maxWidth="xs">
            <Stack spacing={2} alignItems="center" py={2}>
              <Typography variant="h6">Email successfully confirmed</Typography>
              <WelcomeImage style={{ width: "60%", height: "auto" }} />
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </Stack>
          </Container>
        </Paper>
      )}
    </Box>
  );
}

export default ConfirmEmailCallback;
