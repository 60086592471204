import { GetMyProfileQuery, useGetMyProfileQuery } from "graphql/generated";

export type Profile = GetMyProfileQuery["getMyProfile"];
export type TeamProfile = GetMyProfileQuery["getMyProfile"]["teamProfile"];

export function useProfile(skip = false) {
  const {
    data,
    loading: profileLoading,
    error: profileError,
  } = useGetMyProfileQuery({
    // Skip if we're not logged in
    // TODO: How do we know if theres a token?
    skip,
  });

  const profile = data?.getMyProfile;
  const activeTeamProfile = data?.getMyProfile.teamProfile;

  return { profile, activeTeamProfile, profileLoading, profileError };
}
