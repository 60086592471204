import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import Screen from "components/layouts/Screen";
import { useState } from "react";
import { html as frontendChanges } from "../../../CHANGELOG.md";
import { html as backendChanges } from "../../../../graphql/CHANGELOG.md";

function ViewChangelog() {
  const [openTab, setOpenTab] = useState<"frontend" | "backend">("frontend");

  const handleTabChange = (_event: unknown, selectedTabValue: any) =>
    setOpenTab(selectedTabValue);

  return (
    <Screen title="Changelog" backRoute="/">
      <TabContext value={openTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList centered onChange={handleTabChange}>
            <Tab label="Frontend" value="frontend" />
            <Tab label="Backend" value="backend" />
          </TabList>
        </Box>
        <TabPanel value="frontend">
          <div dangerouslySetInnerHTML={{ __html: frontendChanges }} />
        </TabPanel>
        <TabPanel value="backend">
          <div dangerouslySetInnerHTML={{ __html: backendChanges }} />
        </TabPanel>
      </TabContext>
    </Screen>
  );
}

export default ViewChangelog;
