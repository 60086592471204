import { useCallback, useEffect, useState } from "react";
import { AnyObjectSchema, ValidationError } from "yup";

/**
 * @param schema A yup object schema with validations
 * @param object An object to validate against the supplied schema
 * @returns Array of form errors
 */

export type FormError = {
  field: string;
  message: string;
};

export function useValidateForm(schema: AnyObjectSchema, object?: Object) {
  const [formErrors, setFormErrors] = useState<FormError[]>([]);

  function extractErrors(validationErrors: ValidationError): FormError[] {
    if (!validationErrors || !validationErrors.inner) {
      return [];
    }

    return validationErrors.inner.reduce(
      (acc: FormError[], error: ValidationError) => {
        const newError = {
          field: error.path || "Unknown",
          message: error.message,
        };
        acc.push(newError);
        return acc;
      },
      []
    );
  }

  useEffect(() => {
    if (!object) return;
    (async () => {
      try {
        await schema.validate(object, {
          abortEarly: false,
        });
        setFormErrors([]);
      } catch (error) {
        if (error instanceof ValidationError) {
          setFormErrors(extractErrors(error));
        } else {
          throw new Error("Unexpected error validating data");
        }
      }
    })();
  }, [schema, object]);

  const hasError = useCallback(
    (property: string) => !!formErrors.find((err) => err.field === property),
    [formErrors]
  );

  const getErrorMessage = useCallback(
    (property: string) =>
      formErrors.find((err) => err.field === property)?.message,
    [formErrors]
  );

  return { formErrors, hasError, getErrorMessage };
}
