import { createTheme } from '@mui/material/styles';

// https://colorhunt.co/palette/2a09443b185fa12568fec260

// maybe this one is better
// https://colorhunt.co/palette/0000003e065f700b978e05c2

export default createTheme({
    palette: {
        mode: "dark",
        primary: {
            // main: "#700B97"
            main: "#8E05C2" // bright purple
        },
        secondary: {
            main: "#40375C" // dark purple
        }
    }
})