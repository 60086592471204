import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CssBaseline, ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { GlobalContextProvider } from "contexts/GlobalContext";
import React from "react";
import { createRoot } from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import theme from "theme";
import { version } from "../package.json";
import App from "./App";
import ReloadPrompt from "config/ReloadPrompt";

if (!import.meta.env.VITE_BACKEND_ENDPOINT) {
  throw new Error("Missing environment variables");
}

Sentry.init({
  dsn: "https://a72fe8ec7e1f4c83ae471f2f606921f1@o356575.ingest.sentry.io/6220204",
  integrations: [new BrowserTracing()],
  enabled: import.meta.env.PROD,
  release: version,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
        <ReloadPrompt />
      </ThemeProvider>
    </GlobalContextProvider>
    <Toaster
      toastOptions={{
        style: {
          background: "#121212",
          color: "white",
        },
        success: {
          duration: 1000,
          icon: "🎉",
        },
        error: {
          duration: 3000,
        },
      }}
    />
  </React.StrictMode>
);
