import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  CssBaseline,
  Divider,
  Grid,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import PtbLogo from "assets/logo/PTB_Logo_500x500.png";
import { Link as PtbLink, SocialButton } from "components/common";
import { useGlobal } from "contexts/GlobalContext";
import {
  useEmailLoginMutation,
  useGoogleLoginMutation,
} from "graphql/generated";
import { useValidateForm } from "hooks";
import React, { useState } from "react";
import {
  CodeResponse,
  TokenResponse,
  useGoogleLogin,
} from "@react-oauth/google";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email provided")
    .required("No email provided"),
  password: yup
    .string()
    .required("No password provided.")
    .min(8, "Password is too short - must be 8 characters minimum")
    .max(50, "Password is too long - must be 50 characters maximum"),
});

function Login() {
  const { setGlobalState } = useGlobal();
  const [emailLogin] = useEmailLoginMutation();
  const [googleLogin] = useGoogleLoginMutation();
  const [loginForm, setLoginForm] = useState<{
    email: string;
    password: string;
    loginAttempted: boolean;
    errorMessage: string;
    loading: boolean;
  }>({
    email: "",
    password: "",
    loginAttempted: false,
    errorMessage: "",
    loading: false,
  });

  const theme = useTheme();
  const navigate = useNavigate();
  const { hasError, getErrorMessage } = useValidateForm(
    loginFormSchema,
    loginForm
  );

  // const { signIn: googleSignin } = useGoogleLogin({
  //   clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
  //   onSuccess: handleGoogleLogin,
  //   cookiePolicy: "single_host_origin",
  // });

  async function handleEmailLoginPressed(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    setLoginForm((prevState) => ({
      ...prevState,
      loginAttempted: true,
      loading: true,
    }));
    try {
      await loginFormSchema.validate(loginForm, {
        abortEarly: false,
      });
      const loginResult = await emailLogin({
        variables: {
          email: loginForm.email,
          password: loginForm.password,
        },
      });
      const accessToken = loginResult.data?.emailLogin.accessToken;
      if (!accessToken) {
        throw new Error("Error logging in");
      }

      setGlobalState({
        accessToken,
      });
      navigate("/");
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === "ValidationError") {
          // handled by yup, ignoring
        } else if (error.name === "Error") {
          const errorMessage = error.message;
          setLoginForm((prevState) => ({
            ...prevState,
            errorMessage: errorMessage,
          }));
        }
      }
    }
    setLoginForm((prevState) => ({
      ...prevState,
      loading: false,
    }));
  }

  function handleLoginFormChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setLoginForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleGoogleLoginSuccess = async ({
    access_token,
    token_type,
    scope,
    expires_in,
    prompt,
    hd,
    state,
  }: Omit<TokenResponse, "error" | "error_description" | "error_uri">) => {
    try {
      const response = await googleLogin({
        variables: {
          token: access_token,
        },
        fetchPolicy: "network-only",
      });

      const newAccessToken = response.data?.googleLogin.accessToken;
      if (!newAccessToken) {
        throw new Error("Error logging in");
      }

      setGlobalState({
        accessToken: newAccessToken,
      });

      navigate("/");
    } catch (error) {
      if (error instanceof Error) {
        const errorMessage = error.message;
        toast.error(errorMessage);
        setLoginForm((prevState) => ({
          ...prevState,
          errorMessage: errorMessage,
        }));
      }
    }
  };

  const handleGoogleLoginError = (
    errorResponse: Pick<
      CodeResponse,
      "error" | "error_description" | "error_uri"
    >
  ) => {
    const { error, error_description, error_uri } = errorResponse;
    Sentry.setContext("Google Login Error", {
      error_description,
      error_uri,
    });
    Sentry.captureException(error);

    toast.error(error_description ?? "Google login error");
  };

  const handleGoogleLoginPressed = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginError,
    flow: "implicit",
  });

  return (
    <Grid
      container
      component="main"
      sx={{
        height: "100vh",
      }}
    >
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(https://source.unsplash.com/collection/4865572)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            margin: theme.spacing(8, 4),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={PtbLogo} alt="logo" height="125" />

          <Typography
            variant="subtitle1"
            color="inherit"
            sx={{
              my: 1,
            }}
          >
            Welcome back!
          </Typography>
          <Stack spacing={2} width="100%">
            {/* <GoogleLogin
              onSuccess={(credentialResponse) => {
                console.log(credentialResponse);
              }}
              // onSuccess={googleSignin}
              onError={() => {
                console.log("Login Failed");
              }}
              useOneTap
            /> */}
            <SocialButton type="google" onClick={handleGoogleLoginPressed}>
              Sign in with Google
            </SocialButton>

            <Divider textAlign="center">OR</Divider>
            <form onSubmit={handleEmailLoginPressed} style={{ width: "100%" }}>
              <Stack spacing={2} width="100%">
                <TextField
                  name="email"
                  label="Email"
                  value={loginForm.email}
                  onChange={handleLoginFormChange}
                  size="small"
                  error={loginForm.loginAttempted && hasError("email")}
                  helperText={
                    loginForm.loginAttempted && getErrorMessage("email")
                  }
                  fullWidth
                />
                <TextField
                  name="password"
                  label="Password"
                  value={loginForm.password}
                  onChange={handleLoginFormChange}
                  error={loginForm.loginAttempted && hasError("password")}
                  helperText={
                    loginForm.loginAttempted && getErrorMessage("password")
                  }
                  size="small"
                  type="password"
                  fullWidth
                />
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="secondary"
                  loading={loginForm.loading}
                  disabled={loginForm.loading}
                  fullWidth
                >
                  Sign In
                </LoadingButton>
                {loginForm.errorMessage ? (
                  <Alert severity="error">{loginForm.errorMessage}</Alert>
                ) : null}
                <Typography align="center" variant="subtitle2">
                  Don't have an account?{" "}
                  <PtbLink to="/register">Sign up</PtbLink>
                </Typography>
              </Stack>
            </form>
            <Box mt={5}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Stack alignItems="center" display="flex">
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    {"Copyright © "}
                    <Link color="inherit" href="https://parkthebus.com.au">
                      PTB productions
                    </Link>{" "}
                    {new Date().getFullYear()}
                    {"."}
                  </Typography>
                  <Link color="inherit" href="/service" variant="body2">
                    Terms of Service
                  </Link>
                  <Link color="inherit" href="/privacy" variant="body2">
                    Privacy Policy
                  </Link>
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;
