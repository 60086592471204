import { ArrowBack as BackIcon } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { textVariant } from "types";

interface ScreenTitleProps {
  title: string;
  titleVariant?: textVariant;
  backButton?: boolean;
  backRoute?: string;
  customAction?: any;
}

export function ScreenTitle(props: ScreenTitleProps) {
  const {
    title,
    titleVariant = "h6",
    backButton = true,
    backRoute,
    customAction,
  } = props;
  const navigate = useNavigate();

  function handleBackPressed() {
    if (backRoute) {
      navigate(backRoute);
    } else {
      navigate(-1);
    }
  }

  return (
    <Box display="flex" pb={2}>
      <Box width="100%" display="flex" alignItems="center">
        {backButton ? (
          <IconButton onClick={handleBackPressed}>
            <BackIcon />
          </IconButton>
        ) : null}
        <Typography
          flexGrow={1}
          variant={titleVariant}
          color="inherit"
          align="center"
        >
          {title}
        </Typography>
        {customAction ? customAction : <Box width="40px" />}
      </Box>
    </Box>
  );
}
