import {
  useGetPlayersTeamsQuery,
  useToggleActiveTeamMutation,
} from "graphql/generated";
import { useMemo } from "react";

export function usePlayerProfiles() {
  const {
    data,
    loading: playerProfilesLoading,
    error: playerProfilesError,
  } = useGetPlayersTeamsQuery();

  const [toggleActiveTeamMutation] = useToggleActiveTeamMutation();

  const playerProfiles = data?.getPlayersTeams ?? [];

  const handlers = useMemo(
    () => ({
      toggleActiveTeam: (newTeamID: string) =>
        toggleActiveTeamMutation({
          variables: {
            newTeamID,
          },
          refetchQueries: "all",
          awaitRefetchQueries: true,
        }),
    }),
    [toggleActiveTeamMutation]
  );

  return {
    playerProfiles,
    playerProfilesLoading,
    playerProfilesError,
    handlers,
  };
}
