import { Button, Stack } from "@mui/material";
import { usePlayerProfiles } from "hooks";
import { useNavigate } from "react-router-dom";

interface SelectTeamFromListProps {
  teams: { id: string; name: string }[];
}

function SelectTeamFromList(props: SelectTeamFromListProps) {
  const { teams } = props;
  const {
    handlers: { toggleActiveTeam },
  } = usePlayerProfiles();

  const navigate = useNavigate();

  const handleTeamSelected = (teamID: string) => async () => {
    await toggleActiveTeam(teamID);
    navigate("/");
  };

  return (
    <Stack spacing={2}>
      {teams.map(
        (team) =>
          team && (
            <Button variant="outlined" onClick={handleTeamSelected(team.id)}>
              {team.name}
            </Button>
          )
      )}
    </Stack>
  );
}

export default SelectTeamFromList;
