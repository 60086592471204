import { LoadingButton } from "@mui/lab";
import { Alert, Stack, Typography } from "@mui/material";
import Screen from "components/layouts/Screen";
import { useResendConfirmationEmailMutation } from "graphql/generated";
import { useState } from "react";
import toast from "react-hot-toast";

interface AwaitingConfirmationProps {
  email: string;
}

function AwaitingConfirmation(props: AwaitingConfirmationProps) {
  const [loading, setLoading] = useState(false);
  const { email } = props;
  const [resendConfirmationEmailMutation] =
    useResendConfirmationEmailMutation();

  async function handleResendVerification() {
    setLoading(true);
    await toast.promise(resendConfirmationEmailMutation(), {
      loading: "Resending verification email...",
      error: "Error resending verficial email",
      success: "Verification email sent",
    });
    setLoading(false);
  }

  return (
    <Screen title="Awaiting Confirmation" backButton={false}>
      <Stack spacing={2}>
        <Alert severity="success">
          A verification link has been sent to your email account
        </Alert>
        <Typography variant="body2">
          Please click on the link that has been sent to your email account to
          verify your email.
        </Typography>
        <Typography variant="body2">The link is valid for 24 hours</Typography>
        <Typography variant="body2" align="center">
          {email}
        </Typography>
        <LoadingButton
          loading={loading}
          disabled={loading}
          onClick={handleResendVerification}
          variant="contained"
          color="secondary"
        >
          Resend Email
        </LoadingButton>
      </Stack>
    </Screen>
  );
}

export default AwaitingConfirmation;
