import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "utils/stripe";

function WithElements(WrappedComponent: React.ComponentType) {
  return (
    <Elements stripe={stripePromise}>
      <WrappedComponent />
    </Elements>
  );
}

export default WithElements;
