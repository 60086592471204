import { LinearProgress, Paper } from "@mui/material";
import { LoadingTile } from "components/common";
import WithProfile from "components/hoc/WithProfile";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PaymentCallback from "screens/admin/payment/subscription/PaymentCallback";
import ConfirmEmailCallback from "screens/awaitingConfirmation/ConfirmEmailCallback";

const InvitePlayer = lazy(() => import("screens/home/players/InvitePlayer"));
const AddFundsPage = lazy(
  () => import("screens/settings/payments/addFunds/AddFundsPage")
);
const CashOutForm = lazy(() => import("screens/settings/payments/CashOutForm"));
const TakeMoneyForm = lazy(
  () => import("screens/admin/payment/transactions/TakeMoneyForm")
);
const SpendMoneyForm = lazy(
  () => import("screens/admin/payment/transactions/SpendMoneyForm")
);
const Admin = lazy(() => import("screens/admin/Admin"));
const EditReoccuring = lazy(() => import("screens/admin/games/EditReoccuring"));
const ReoccuringGamesList = lazy(
  () => import("screens/admin/games/ReoccuringGamesList")
);
const EditPlayer = lazy(() => import("screens/admin/players/EditPlayer"));
const EditVenue = lazy(() => import("screens/admin/venues/EditVenue"));
const VenuesList = lazy(() => import("screens/admin/venues/VenuesList"));
const EditGame = lazy(() => import("screens/games/EditGame"));
const FinaliseGame = lazy(() => import("screens/games/FinaliseGame"));
const GameHistory = lazy(() => import("screens/games/history/GameHistory"));
const UpcomingGames = lazy(() => import("screens/games/UpcomingGames"));
const ViewGame = lazy(() => import("screens/games/ViewGame"));
const CreateTeam = lazy(() => import("screens/teams/CreateTeam"));
const JoinTeam = lazy(() => import("screens/home/players/JoinTeam"));
const Home = lazy(() => import("screens/home/Home"));
const PlayersList = lazy(() => import("screens/home/players/PlayersList"));
const Login = lazy(() => import("screens/login/Login"));
const Profile = lazy(() => import("screens/profile/Profile"));
const Settings = lazy(() => import("screens/settings/Settings"));
const RulesList = lazy(() => import("screens/rules/RulesList"));
const EditPostGameReview = lazy(
  () => import("screens/reviews/EditPostGameReview")
);
const PostGameReviewsList = lazy(
  () => import("screens/reviews/PostGameReviewsList")
);
const ViewVenue = lazy(() => import("screens/venues/ViewVenue"));
// const ViewChangelog = lazy(() => import("screens/changelog/ViewChangelog"));
import ViewChangelog from "screens/changelog/ViewChangelog";
const Register = lazy(() => import("screens/register/Register"));
const EditTeam = lazy(() => import("screens/teams/EditTeam"));
const PaymentSettings = lazy(
  () => import("screens/admin/payment/PaymentSettings")
);
const TermsOfService = lazy(
  () => import("screens/legal/termsOfService/TermsOfService")
);

function AppRoutes() {
  const miscRoutes = [
    {
      path: "/",
      element: WithProfile(Home),
    },
    {
      path: "/venues/:venueid",
      element: <ViewVenue />,
    },
    {
      path: "/payment/success",
      element: PaymentCallback,
    },
    {
      path: "/rules",
      element: WithProfile(RulesList),
    },
    {
      path: "/profile/:playerid",
      element: WithProfile(Profile),
    },
    {
      path: "/changelog",
      element: <ViewChangelog />,
    },
  ];

  const teamsRoutes = [
    {
      path: "/teams/create",
      element: <CreateTeam />,
    },
    {
      path: "/teams/join",
      element: <JoinTeam />,
    },
    {
      path: "/teams/:teamid",
      element: <EditTeam />,
    },
  ];

  const gamesRoutes = [
    {
      path: "/games",
      element: WithProfile(UpcomingGames),
    },
    {
      path: "/games/:gameid",
      element: WithProfile(ViewGame),
    },
    {
      path: "/games/:gameid/finalise",
      element: <FinaliseGame />,
    },
    {
      path: "/games/:gameid/edit",
      element: <EditGame />,
    },
    {
      path: "/games/history",
      element: <GameHistory />,
    },
  ];

  const adminRoutes = [
    {
      path: "/admin",
      element: WithProfile(Admin),
    },
    {
      path: "/admin/venues",
      element: <VenuesList />,
    },
    {
      path: "/admin/reoccuring",
      element: <ReoccuringGamesList />,
    },
    {
      path: "/admin/payment/take",
      element: <TakeMoneyForm />,
    },
    {
      path: "/admin/payment/spend",
      element: <SpendMoneyForm />,
    },

    {
      path: "/admin/payment",
      element: <PaymentSettings />,
    },
    {
      path: "/admin/reoccuring/:reoccuringid",
      element: <EditReoccuring />,
    },
    {
      path: "/admin/venues/:venueid",
      element: <EditVenue />,
    },
  ];

  const settingsRoutes = [
    {
      path: "/settings",
      element: <Settings />,
    },
    {
      path: "/settings/balance/cashout",
      element: <CashOutForm />,
    },
    {
      path: "/settings/balance/add",
      element: <AddFundsPage />,
    },
  ];

  const reviewsRoutes = [
    {
      path: "/reviews",
      element: <PostGameReviewsList />,
    },
    {
      path: "/reviews/:gameid/:reviewid",
      element: <EditPostGameReview />,
    },
  ];

  const playerRoutes = [
    {
      path: "/players",
      element: WithProfile(PlayersList),
    },
    {
      path: "/players/:teamprofileid",
      element: WithProfile(EditPlayer),
    },
    {
      path: "/players/invite",
      element: WithProfile(InvitePlayer),
    },
  ];

  const publicRoutes = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/service",
      element: <TermsOfService />,
    },
    {
      path: "/auth/email/confirm/:token",
      element: <ConfirmEmailCallback />,
    },
  ];

  const allRoutes = [
    ...miscRoutes,
    ...teamsRoutes,
    ...gamesRoutes,
    ...adminRoutes,
    ...settingsRoutes,
    ...reviewsRoutes,
    ...playerRoutes,
    ...publicRoutes,
  ];

  return (
    <Suspense
      fallback={
        <Paper sx={{ backgroundColor: "#121212", minHeight: "100vh" }}>
          <LinearProgress color="inherit" />
          <LoadingTile message="Loading Page..." align="center" />
        </Paper>
      }
    >
      <Routes>
        {allRoutes.map((screen) => (
          <Route
            key={screen.path}
            path={screen.path}
            element={screen.element}
          />
        ))}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
