import { useGetActiveTeamQuery } from "graphql/generated";

export function useActiveTeamProfile() {
  const {
    data,
    loading: activeTeamLoading,
    error: activeTeamError,
  } = useGetActiveTeamQuery();

  const activeTeam = data?.getPlayersActiveTeamProfile;

  return { activeTeam, activeTeamLoading, activeTeamError };
}
