import { Box, Container, Paper } from "@mui/material";
import { ScreenTitle } from "components/common";
import { textVariant } from "types";
import Footer from "../global/Footer";
import TopNav from "../global/TopNav";

interface ScreenProps {
  title?: string;
  titleVariant?: textVariant;
  backButton?: boolean;
  backRoute?: string;
  customAction?: any;
  children?: JSX.Element | JSX.Element[];
}

function Screen(props: ScreenProps) {
  return (
    <Paper
      sx={{
        bgcolor: "background.default",
      }}
    >
      <Container
        disableGutters
        maxWidth="xs"
        sx={{
          borderRadius: 0,
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <TopNav />
        <Box
          component={Paper}
          sx={{
            borderRadius: 0,
            flexGrow: 1,
          }}
        >
          <Box mx={1} mt={1}>
            {props.title ? (
              <ScreenTitle title={props.title} {...props} />
            ) : null}
            {props.children ?? null}
          </Box>
        </Box>
        <Footer />
      </Container>
    </Paper>
  );
}

export default Screen;
