import {
  AttachMoney as MoneyIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import { AppBar, Box, Chip, Fab, Toolbar, Typography } from "@mui/material";
import PtbLogo from "assets/logo/PTB_Icon_50x50.png";
import { ScrollTop } from "components/common";
import { useBalance } from "hooks";
import { useNavigate } from "react-router-dom";
import AccountMenu from "./AccountMenu";
import NotificationMenu from "./notifications/NotificationMenu";

function TopNav() {
  const navigate = useNavigate();
  const { balance } = useBalance();

  return (
    <>
      <AppBar>
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Box display="flex" alignItems="center">
              <img
                src={PtbLogo}
                width="35"
                alt="PTB Logo"
                style={{ marginRight: "10px" }}
                onClick={() => navigate("/")}
              />
              <Typography
                variant="h6"
                component="a"
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                PTB
              </Typography>
            </Box>
          </Box>
          <Chip
            icon={<MoneyIcon />}
            color={balance >= 0 ? "success" : "error"}
            label={balance}
            size="small"
            onClick={() => navigate("/settings/balance/add")}
            sx={{ mx: 1 }}
          />
          <NotificationMenu />
          <AccountMenu />
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
}

export default TopNav;
